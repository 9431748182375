<template>
  <div class="investments-report-progress-table">
    <vs-table
      :columns="columns"
      :rows="rows"
      :show-search="false"
      :show-pagination="false"
    >
      <template v-slot:row="{ row, column, index }" >
        <span v-if="column.isEditable && editable">
          <b-form-input
            v-model="row[column.field]"
            @input="() => updateProgressRow(index, column.field, row[column.field])"
            :state="isEditableValid(row[column.field])"
            placeholder="0"
            />
        </span>
        <span v-else-if="column.materialId && editable">
          <b-form-input
            v-model="getMaterialValue(row, column).quantity"
            @input="() => updateProgressMaterial(index, row, column)"
            :state="isEditableValid(getMaterialValue(row, column).quantity)"
            placeholder="0"
            />
        </span>
        <span v-else-if="column.field === 'material'">
          {{ row.material.find((m) => m.materialId === column.materialId).quantity }}
          {{ row.material.find((m) => m.materialId === column.materialId).unit }}
        </span>
        <span v-else-if="column.unit">
          {{ row[column.field] }} {{ column.unit }}
        </span>
      </template>
    </vs-table>
  </div>
</template>

<script>
import { BFormInput } from 'bootstrap-vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import { computed, ref } from 'vue';
import i18n from '@/libs/i18n';
import store from '@/store';

export default {
  name: 'InvestmentsReportProgressTable',
  props: {
    progress: {
      type: Array,
      default: () => [],
    },
    editable: {
      type: Boolean,
      default: false,
    },
  },
  setup(props, { emit }) {
    const progress = ref(props.progress);
    const getProgressUsage = () => progress.value?.[0]?.daily_balance_usage ?? [];

    const getUnit = store.getters['units/byId'];

    const columns = computed(() => {
      const tableI18n = 'investments.form.report.work.table';
      const permanent = [
        {
          label: i18n.t(`${tableI18n}.technology`),
          field: 'technology',
        },
        {
          label: i18n.t(`${tableI18n}.diameter`),
          field: 'diameter',
        },
        {
          label: i18n.t(`${tableI18n}.quantity`),
          field: 'quantity',
          isEditable: true,
        },
        {
          label: i18n.t(`${tableI18n}.mb`),
          field: 'current_meter',
          unit: 'm',
          isEditable: true,
        },
        {
          label: i18n.t(`${tableI18n}.project_mb`),
          field: 'project_current_meter',
          unit: 'm',
          isEditable: true,
        },
      ];

      const materials = getProgressUsage().map((usage) => ({
        label: usage.requirement_group.name,
        materialId: usage.requirement_group.id,
        field: 'material',
      }));

      return [...permanent, ...materials];
    });

    const rows = ref(props.progress.map((p) => ({
      technology: p.subject?.technology?.name,
      diameter: `${Math.floor(p.subject?.diameter)}mm`,
      quantity: `${Math.floor(p.quantity)}`,
      current_meter: p.current_meter,
      project_current_meter: p.project_current_meter,
      material: p.daily_balance_usage.map((usage) => ({
        materialId: usage.requirement_group.id,
        quantity: usage.quantity,
        unit: getUnit(usage.requirement_group.core_unit_id)?.code,
      })),
    })));

    const getMaterialValue = (row, column) => row.material
      .find((m) => m.materialId === column.materialId);

    const updateProgressRow = (idx, field, value) => {
      emit('updateRow', { idx, field, value });
    };

    const updateProgressMaterial = (idx, row, column) => {
      const value = getMaterialValue(row, column).quantity;
      const { materialId } = column;

      emit('updateMaterial', { idx, materialId, value });
    };

    const isEditableValid = (val) => {
      if (!val) return null;
      return !Number.isNaN(Number(val.replace(',', '.')));
    };

    return {
      columns,
      rows,
      getUnit,
      updateProgressRow,
      getMaterialValue,
      updateProgressMaterial,
      isEditableValid,
    };
  },
  components: {
    VsTable,
    BFormInput,
  },
};
</script>
<style lang="sass" scoped>
.investments-report-progress-table
  width: 100%
  overflow-x: auto
</style>
