<template>
  <div class="investments-processing-table">

    <div class="filters">

      <div class="picker-wrapper">
        <investments-investment-picker
          v-model="pickedInvestment"
          :reduce="investment => investment.id"
          placeholder="Wybierz inwestycję"
        />
      </div>

      <b-dropdown dropleft no-caret size="sm" class="filter-dropdown">
        <template #button-content>
          <feather-icon icon="MenuIcon" />
        </template>
        <b-dropdown-form>
          <div class="checkbox-group-wrapper">
            <b-form-checkbox-group stacked
              v-model="enabledColumns"
              :options="allFields"
              value-field="field"
              text-field="name"
            />
          </div>
        </b-dropdown-form>
      </b-dropdown>
    </div>

    <investments-processing-summary
      v-if="investmentData"
      :investment="investmentData"
    />

    <div class="table-wrapper">
      <table>
        <thead>
          <tr>
            <th
              v-for="(head, idx) in computedTable"
              :key="`head-${idx}`"
              :rowspan="head.rowspan"
              :colspan="head.colspan"
            >
              {{ head.name }}
            </th>
          </tr>
          <tr>
            <th
              v-for="(head, idx) in computedChildren"
              :key="`head-c-${idx}`"
            >
              {{ head.name }}
            </th>
          </tr>
        </thead>

        <tbody>
          <tr v-for="(row, idx) in computedRows" :key="`report-row-${idx}`">
            <td v-for="(td, tidx) in row" :key="`report-row-${idx}-${tidx}`">
              <template v-if="td.name === 'day'">
                {{ td.value }}
              </template>

              <template v-else-if="td.name === 'date'">
                {{ formatDate.training(td.value) }}
                ({{ getWeekday(td.value) }})
              </template>

              <template v-else-if="td.name === 'resource'">
                <base-resource-label :id="td.value?.id" :resource="td.value" />
              </template>

              <template v-else-if="td.name === 'diameter'">
                {{ td.value }} mm
              </template>

              <template v-else-if="td.name === 'subject.done_percentage'">
                {{ td.value }}%
              </template>

              <template v-else-if="td.name === 'average_performance'">
                {{ td.value }} szt.
              </template>

              <template v-else-if="td.name.startsWith('subject')">
                {{ td.value }} szt.
              </template>

              <template v-else-if="td.name.startsWith('current_meter')">
                {{ td.value }} m
              </template>

              <template v-else-if="td.name === 'usages.0.real_usage'">
                {{ td.value }} %
              </template>

              <template v-else-if="td.name === 'usages.0.real_usage_sum'">
                {{ td.value }} %
              </template>

              <template v-else-if="td.name.startsWith('usages')">
                {{ td.value }} m3
              </template>

              <template v-else>
                {{ td.value }}
              </template>
            </td>
          </tr>
          <tr v-if="computedRows.length === 0">
            <td
              class="no-data-info"
              :colspan="allFields.length"
            >
              Brak danych dla wybranej inwestycji
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import {
  BFormCheckboxGroup,
  BDropdown,
  BDropdownForm,
} from 'bootstrap-vue';
import { computed, ref } from 'vue';
import formatDate, { getWeekday } from '@/libs/date-formatter';
import InvestmentsInvestmentPicker from '@/components/module/investments/InvestmentsInvestmentPicker.vue';
import BaseResourceLabel from '../base/resource/BaseResourceLabel.vue';
import InvestmentsProcessingSummary from './InvestmentsProcessingSummary.vue';

export default {
  name: 'InvestmentsProcessingTable',
  emits: ['investment:update'],
  props: {
    rows: {
      type: Array,
      default: () => [],
    },
    selectedInvestment: {
      type: Number,
    },
    investmentData: {
      type: Object,
    },
  },
  setup(props, { emit }) {
    const flattenObject = (nestedObject, propertyPrefix = null) => {
      if (Object.keys(nestedObject).length === 0) return null;

      let emptiedObject = {};

      Object
        .entries(nestedObject)
        .forEach(([key, val]) => {
          const keyWithPrefix = propertyPrefix ? `${propertyPrefix}.${key}` : key;
          if (typeof val === 'object') {
            emptiedObject = { ...emptiedObject, [key]: val, ...flattenObject(val, keyWithPrefix) };
          } else {
            emptiedObject[keyWithPrefix] = val;
          }
        });

      return emptiedObject;
    };

    const table = [
      { name: 'Dzień pracy', field: 'day' },
      { name: 'Data', field: 'date' },
      { name: 'Palownica', field: 'resource' },
      { name: 'Technologia', field: 'technology' },
      { name: 'Średnica kolumn', field: 'diameter' },
      {
        name: 'Liczba pali',
        field: 'subject',
        children: [
          { name: 'dnia', field: 'subject.quantity' },
          { name: 'razem', field: 'subject.sum' },
          { name: 'procent. zaawan.', field: 'subject.done_percentage' },
          { name: 'zostało', field: 'subject.progress' },
        ],
      },
      { name: 'Średnia wydajność', field: 'average_performance' },
      {
        name: 'mb pali rzeczywiste',
        field: 'current_meter',
        children: [
          { name: 'dnia', field: 'current_meter.quantity' },
          { name: 'razem', field: 'current_meter.sum' },
          { name: 'średnio', field: 'current_meter.average' },
          { name: 'śr. dł.', field: 'current_meter.average_length' },
        ],
      },
      {
        name: 'mb projektowe',
        field: 'mb_project',
        children: [
          { name: 'dnia', field: 'project_meter.quantity' },
          { name: 'razem', field: 'project_meter.sum' },
        ],
      },
      { name: 'Beton', field: 'usages.0.quantity' },
      { name: 'Beton razem', field: 'usages.0.sum' },
      {
        name: 'Zużycie betonu',
        field: 'concrete_usage',
        children: [
          { name: 'teoretyczne', field: 'usages.0.teoretical_usage' },
          { name: 'teoretyczne narastająco', field: 'usages.0.teoretical_usage_sum' },
          { name: 'rzeczywiste', field: 'usages.0.real_usage' },
          { name: 'narastająco', field: 'usages.0.real_usage_sum' },
        ],
      },
    ];

    const enabledColumns = ref(table.reduce((acc, th) => [
      ...acc,
      th.field,
      ...(th?.children?.map(({ field }) => field) ?? []),
    ], []));

    // wtf
    const realColumns = ref(table.reduce((acc, th) => [
      ...acc,
      ...(th?.children?.map(({ field }) => field) ?? [th.field]),
    ], []));

    const allFields = computed(() => table
      .reduce((acc, th) => [
        ...acc,
        ...(th?.children?.map((th2) => ({
          name: `${th.name} - ${th2.name}`,
          field: th2.field,
        })) ?? [{ name: th.name, field: th.field }]),
      ], []));

    const computedTable = computed(() => table
      .filter(({ field }) => enabledColumns.value.includes(field))
      .filter((th) => {
        if (!th.children) return true;

        const enabledChildren = th?.children
          ?.filter(({ field }) => enabledColumns.value.includes(field));

        return !!enabledChildren.length;
      })
      .map((th) => {
        const enabledChildren = th?.children
          ?.filter(({ field }) => enabledColumns.value.includes(field));

        return {
          ...th,
          rowspan: enabledChildren?.length ? '1' : '2',
          colspan: enabledChildren?.length ? enabledChildren.length : '1',
        };
      }));

    const computedChildren = computed(() => table
      .filter((th) => th.children)
      .reduce((acc, th) => [
        ...acc,
        ...th.children.filter(({ field }) => enabledColumns.value.includes(field)),
      ], []));

    const flattenedRows = computed(() => props.rows.map((row) => flattenObject(row)));
    const computedRows = computed(() => flattenedRows.value
      .map((row) => realColumns.value
        .filter((column) => enabledColumns.value.includes(column))
        .map((column) => ({ name: column, value: row[column] }))));

    const pickedInvestment = computed({
      get: () => props.selectedInvestment,
      set: (val) => emit('investment:update', val),
    });

    return {
      allFields,
      table,
      computedTable,
      computedChildren,
      enabledColumns,
      computedRows,
      flattenedRows,
      formatDate,
      getWeekday,
      pickedInvestment,
    };
  },
  components: {
    BFormCheckboxGroup,
    BDropdown,
    BDropdownForm,
    BaseResourceLabel,
    InvestmentsInvestmentPicker,
    InvestmentsProcessingSummary,
  },
};
</script>

<style lang="scss">
.investments-processing-table {
  display: flex;
  flex-direction: column;
  gap: 20px;

  .filters {
    display: flex;
    align-items: center;

    .picker-wrapper {
      display: flex;
      min-width: 240px;
      flex-direction: column;
      gap: 5px;
    }

    .investments-investment-picker {
      min-width: 250px;
    }
    .filter-dropdown {
      margin-left: auto;
    }
  }

  .checkbox-group-wrapper {
    max-height: 333px;
    overflow-y: auto;

    .custom-checkbox .custom-control-label {
      white-space: nowrap;
    }
  }

  .table-wrapper {
    overflow-x: auto;
    padding-bottom: 30px;

    table {
      text-align: center;
      min-width: 100%;

      th {
        border: 1px solid var(--vs-card-border);
        padding: 5px;
        width: auto;
        white-space: nowrap;
      }

      td {
        white-space: nowrap;
        padding: 10px 10px;
        &.no-data-info {
          padding: 20px 0;
          text-align: left;
        }
      }
    }
  }
}
</style>
