<template>
  <div class="investments-create-modal">
    <b-modal
      id="modal-investments-create"
      cancel-variant="outline-secondary"
      size="lg"

      :title="$t('investments.modal.investment.create.title')"

      v-model="investmentCreateModal"
      @ok.prevent="() => {}"
      no-close-on-backdrop
    >
      <vs-loader full
        :text="$t('investments.modal.investment.create.loading')"
        v-if="isLoading"
      />

      <investments-create-form
        v-model="fields"
        :feedback="feedback"
        @updateSerial="(payload) => fields.serial = payload"
      />

      <template #modal-footer>
        <vs-modal-footer
          :is-loading="isLoading"

          :text-cancel="$t('investments.modal.investment.create.cancel')"
          :text-ok="$t('investments.modal.investment.create.confirm')"

          @ok="createInvestment"
          @cancel="investmentCreateModal = false"
        >
          <template #left>
            <b-form-checkbox v-model="continueEditing" name="check-button">
              Kontynuuj edycję inwestycji
            </b-form-checkbox>
          </template>
        </vs-modal-footer>
      </template>

    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import {
  BFormCheckbox,
} from 'bootstrap-vue';
import showToast from '@/libs/toasts';
import { CreateInvestmentError, CreateInvestmentSuccess } from '@/libs/toasts/feedback/module/investments';
import router from '@/router';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import InvestmentsTable from '@/hooks/tables/investments/investments';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import RemoteResourceManager from '@/libs/RemoteResourceManager';
import InvestmentsCreateForm from '../forms/InvestmentsCreateForm.vue';

export default {
  name: 'InvestmentsCreateModal',
  props: {},
  setup() {
    const { investmentCreateModal } = useInvestmentsStore();
    const isLoading = ref(false);

    const {
      fields, feedback, validateAllFields, errors, resetFields,
    } = useValidatedForm({
      name: 'required',
      shortcut: 'required',
      core_branch_id: 'required|integer',
      serial: 'required',
      crm_principal_id: 'required|integer',
      investment_status_id: 'required|integer',
    }, 'investments.form.investment.field');

    const continueEditing = ref(true);

    watch(investmentCreateModal, resetFields);

    const createInvestment = async () => {
      const validFields = await validateAllFields();
      if (!validFields.every((field) => field)) return;

      isLoading.value = true;

      useInvestmentsApi()
        .createInvestment({ ...fields.value })
        .then(({ data }) => {
          RemoteResourceManager.getInstance().notify('investments.investment');
          showToast(CreateInvestmentSuccess, { name: fields.value.serial });
          InvestmentsTable.value.fetchData();

          if (continueEditing.value) {
            router.push({
              name: 'investments.details.settings',
              params: {
                id: data.data.id,
              },
            });
          }

          investmentCreateModal.value = false;
          isLoading.value = false;
        })
        .catch(({ response }) => {
          isLoading.value = false;
          showToast(CreateInvestmentError, { name: fields.value.serial });
          errors.serverSide.value = response?.data?.errors;
        });
    };

    return {
      fields,
      feedback,
      investmentCreateModal,
      isLoading,
      continueEditing,
      createInvestment,
    };
  },
  components: {
    VsLoader,
    InvestmentsCreateForm,
    BFormCheckbox,
    VsModalFooter,
  },
};
</script>

<style lang="sass" scoped>
.investment-modal-footer
  display: flex
  align-items: center
  width: 100%
  justify-content: space-between
</style>
