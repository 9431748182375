var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('investments.global.field.status')
    }
  }, [_c('investments-status-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.status,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "status", $$v);
      },
      expression: "filters.status"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('investments.global.field.region')
    }
  }, [_c('core-branch-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.regions,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "regions", $$v);
      },
      expression: "filters.regions"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Technologia"
    }
  }, [_c('base-technology-picker', {
    attrs: {
      "multiple": ""
    },
    model: {
      value: _vm.filters.subject_technologies,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "subject_technologies", $$v);
      },
      expression: "filters.subject_technologies"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Typ robót"
    }
  }, [_c('investments-work-type-picker', {
    attrs: {
      "multiple": "",
      "reduce": function (type) {
        return type.id;
      }
    },
    model: {
      value: _vm.filters.work_types,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "work_types", $$v);
      },
      expression: "filters.work_types"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data startu od"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę początkową",
      "reset-button": ""
    },
    model: {
      value: _vm.dateFrom,
      callback: function ($$v) {
        _vm.dateFrom = $$v;
      },
      expression: "dateFrom"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Data startu do"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę końcową",
      "reset-button": ""
    },
    model: {
      value: _vm.dateTo,
      callback: function ($$v) {
        _vm.dateTo = $$v;
      },
      expression: "dateTo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "3"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Zleceniodawca"
    }
  }, [_c('crm-client-picker', {
    attrs: {
      "multiple": "",
      "reduce": function (client) {
        return client.id;
      }
    },
    model: {
      value: _vm.filters.principals,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "principals", $$v);
      },
      expression: "filters.principals"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }