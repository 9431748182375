<template>
  <div class="investments-reports-table">
    <vs-table showActionButton
      mode="remote"
      :columns="columns"
      :rows="$store.state.investments.reports.reports"
      :totalRecords="table.totalRecords"
      :current-page="table.page"
      :checkSort="getColumnSortType"
      :perPage="table.perPage"
      :searchTerm="searchTerm"
      :actionItems="actionItems"
      :isLoading="table.isLoading"
      @on-row-click="openDetails"
      @searchTermChange="(term) => updateSearchTerm(term)"
      @on-page-change="onPageChange"
      @on-sort-change="onSortChange"
      @on-per-page-change="onPageChange"
    >
      <template v-slot:row="{ column, row }" >
        <span v-if="column.field === 'work_hours'">
          {{ formatDate.getTime(row.work_scheduler.start_date) }}
          -
          {{ formatDate.getTime(row.work_scheduler.end_date) }}
        </span>
        <span v-else-if="column.field === 'brigade'">
          {{ row.brigade.name }}
        </span>
        <span v-else-if="column.field === 'investment'" @click.stop>
          <investments-investment-label
            :investment="row.brigade.investment"
          />
        </span>
        <span v-else-if="column.field === 'reporter'">
          <user-label
            withAvatar
            :id="row.reporter_id"
          />
        </span>
        <span v-else-if="column.field === 'daily_balance_date'">
          {{ formatDate.baseReport(row.daily_balance_date) }}
        </span>
      </template>
    </vs-table>

    <investments-report-edit-modal />
  </div>
</template>

<script>
import VsTable from '@/components/ui/vs-table/Table.vue';
import investmentsReports from '@/hooks/tables/investments/investmentsReports';
import formatDate from '@/libs/date-formatter';
import UserLabel from '@/components/ui/UserLabel.vue';
import useInvestmentsModals from '@/hooks/investments/useInvestmentsModals';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import i18n from '@/libs/i18n';
import { deleteModal } from '@/libs/modals';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import showToast from '@/libs/toasts';
import { DeleteInvestmentReportError, DeleteInvestmentReportSuccess } from '@/libs/toasts/feedback/module/investments';
import InvestmentsReportEditModal from './modals/InvestmentsReportEditModal.vue';
import InvestmentsInvestmentLabel from './InvestmentsInvestmentLabel.vue';

export default {
  name: 'InvestmentsReportsTable',
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, rows, changeSorting, getColumnSorting,
    } = investmentsReports.value;
    const { modalReportDetails } = useInvestmentsModals();
    const { investmentEditReportModal } = useInvestmentsStore();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        requiresPermission: 'investment.subject_balance.update',
        callback: (obj) => {
          investmentEditReportModal.value.data = { ...obj };
          investmentEditReportModal.value.visible = true;
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        requiresPermission: 'investment.subject_balance.destroy',
        callback: async (obj) => {
          const confirmation = await deleteModal({
            title: i18n.t('investments.modal.report.delete.title'),
            text: i18n.t('investments.modal.report.delete.text', {
              date: obj.daily_balance_date,
            }),
          });

          if (!confirmation) return false;

          const request = useInvestmentsApi()
            .deleteReport(obj.id)
            .then(() => {
              showToast(DeleteInvestmentReportSuccess);
              fetchData();
            })
            .catch(() => {
              showToast(DeleteInvestmentReportError);
            });

          return request;
        },
      },
    ];

    const openDetails = ({ row }) => {
      modalReportDetails.value.data = { ...row };
      modalReportDetails.value.visible = true;
    };

    const onSortChange = (data) => {
      const { field, type } = data[0];
      changeSorting({ field, type });
    };

    const getColumnSortType = (field) => getColumnSorting(field)?.type;

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      formatDate,
      openDetails,
      onSortChange,
      getColumnSortType,
    };
  },
  components: {
    VsTable,
    InvestmentsInvestmentLabel,
    UserLabel,
    InvestmentsReportEditModal,
  },
};
</script>
