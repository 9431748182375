var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-investment-form"
  }, [_c('vs-form', {
    attrs: {
      "errors": _vm.errors,
      "i18nNamespace": "investments.form.investment.field"
    },
    model: {
      value: _vm.investment,
      callback: function ($$v) {
        _vm.investment = $$v;
      },
      expression: "investment"
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "name",
      "cols": "9",
      "labelCols": "4"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.field.name')
    },
    model: {
      value: _vm.investment.name,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "name", $$v);
      },
      expression: "investment.name"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "shortcut",
      "noLabel": "",
      "cols": "3"
    }
  }, [_c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": _vm.$t('investments.form.investment.field.shortcut')
    },
    model: {
      value: _vm.investment.shortcut,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "shortcut", $$v);
      },
      expression: "investment.shortcut"
    }
  })], 1)], 1), _c('vs-form-group', {
    attrs: {
      "field": "core_branch_id"
    }
  }, [_c('core-branch-picker', {
    model: {
      value: _vm.investment.core_branch_id,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "core_branch_id", $$v);
      },
      expression: "investment.core_branch_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "crm_principal_id",
      "integration": "crm_address_relation"
    }
  }, [_c('crm-client-picker', {
    attrs: {
      "reduce": function (company) {
        return company.id;
      }
    },
    model: {
      value: _vm.investment.crm_principal_id,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "crm_principal_id", $$v);
      },
      expression: "investment.crm_principal_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "investment_status_id"
    }
  }, [_c('investments-status-picker', {
    attrs: {
      "autopick": ""
    },
    model: {
      value: _vm.investment.investment_status_id,
      callback: function ($$v) {
        _vm.$set(_vm.investment, "investment_status_id", $$v);
      },
      expression: "investment.investment_status_id"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "serial"
    }
  }, [_c('div', {
    staticClass: "editable-spans"
  }, [_c('span', {
    attrs: {
      "contenteditable": "",
      "spellcheck": "false"
    },
    on: {
      "blur": function (e) {
        return _vm.updateSerialKey(e, 'id');
      }
    }
  }, [_vm._v(_vm._s(_vm.serial.id))]), _c('span', [_vm._v("/" + _vm._s(_vm.serial.branch || 'X') + "/")]), _c('span', {
    attrs: {
      "contenteditable": "",
      "spellcheck": "false"
    },
    on: {
      "blur": function (e) {
        return _vm.updateSerialKey(e, 'year');
      }
    }
  }, [_vm._v(_vm._s(_vm.serial.year))]), _c('span', [_vm._v("/" + _vm._s(_vm.serial.shortcut || 'X') + "/")]), _c('span', {
    attrs: {
      "contenteditable": "",
      "spellcheck": "false"
    },
    on: {
      "blur": function (e) {
        return _vm.updateSerialKey(e, 'provider');
      }
    }
  }, [_vm._v(_vm._s(_vm.serial.provider))])])])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }