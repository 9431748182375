import i18n from '@/libs/i18n';
import { ref } from 'vue';
import createTable from '@/hooks/tables/createTable';

const investmentsColumns = [
  {
    label: i18n.t('investments.global.field.name'),
    field: 'name',
  },
  {
    label: i18n.t('investments.global.field.investor'),
    field: 'investor',
  },
  {
    label: i18n.t('investments.global.field.principal'),
    field: 'principal',
  },
  {
    label: i18n.t('investments.global.field.branch'),
    field: 'core_branch_id',
  },
  {
    label: i18n.t('investments.global.field.date_start'),
    field: 'estimatedScheduler.start_date',
  },
  {
    label: i18n.t('investments.global.field.date_end'),
    field: 'estimatedScheduler.end_date',
  },
  {
    label: i18n.t('investments.global.field.status'),
    field: 'investment_status_id',
  },
  {
    label: i18n.t('investments.global.field.work_type'),
    field: 'work_type',
  },
  {
    label: i18n.t('investments.global.field.percentage'),
    field: 'percentage',
  },
];

export default ref(createTable('investments', investmentsColumns, {
  filters: {
    status: [],
    regions: [],
    subject_technologies: [],
    work_types: [],
    principals: [],
    estimated_start_date_from: null,
    estimated_start_date_to: null,
  },
  sortable: ['estimatedScheduler.start_date', 'estimatedScheduler.end_date'],
}));
