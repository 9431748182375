<template>
  <div class="investments-processing">
    <b-card>
      <vs-loader
        full
        style="top: 0"
        text="Pobieranie danych"
        v-if="isLoading"
      />
      <investments-processing-table
        :selectedInvestment="pickedInvestment"
        :investmentData="fetchedInvestmentData"
        @investment:update="(val) => pickedInvestment = val"
        :rows="workReport"
      />
    </b-card>
  </div>
</template>

<script>
import { BCard } from 'bootstrap-vue';
import InvestmentsProcessingTable from '@/components/views/investments/InvestmentsProcessingTable.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { ref, watch } from 'vue';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import { useRouter } from '@/@core/utils/utils';

export default {
  name: 'InvestmentsProcessing',
  setup() {
    const pickedInvestment = ref(null);
    const fetchedInvestmentData = ref(null);
    const workReport = ref([]);
    const isLoading = ref(false);

    const { route } = useRouter();
    pickedInvestment.value = Number(route.value.params?.investmentId) ?? null;

    const fetchWorkReport = () => {
      if (!pickedInvestment.value) return;
      workReport.value = [];
      fetchedInvestmentData.value = null;
      isLoading.value = true;

      useInvestmentsApi()
        .fetchWorkReport(pickedInvestment.value)
        .then(({ data }) => {
          workReport.value = data.data.reports;
          fetchedInvestmentData.value = data.data.investment;
        })
        .finally(() => {
          isLoading.value = false;
        });
    };

    if (pickedInvestment.value) fetchWorkReport();

    watch(() => pickedInvestment.value, fetchWorkReport);

    return {
      workReport,
      pickedInvestment,
      fetchWorkReport,
      isLoading,
      fetchedInvestmentData,
    };
  },
  components: {
    BCard,
    InvestmentsProcessingTable,
    VsLoader,
  },
};
</script>
