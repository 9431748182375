var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalFuelingDetails
    }
  }, [_c('div', {
    staticClass: "investments-fueling-details-modal"
  }, [_c('div', {
    staticClass: "controls"
  }, [_c('widget-card-controls', {
    on: {
      "edit": function ($event) {
        return _vm.$emit('edit', _vm.fueling);
      },
      "delete": function ($event) {
        return _vm.$emit('delete', _vm.fueling);
      }
    }
  })], 1), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Data dostawy")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.formatDate.investmentFuel(_vm.fueling.order_date)) + " ")])]), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Inwestycja")]), _c('div', {
    staticClass: "value"
  }, [_c('investments-investment-label', {
    attrs: {
      "investment": _vm.fueling.investment
    }
  })], 1)]), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Ilość paliwa")]), _c('div', {
    staticClass: "value"
  }, [_vm._v(" " + _vm._s(_vm.fueling.amount_fuel) + " l ")])]), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Dostawca")]), _c('div', {
    staticClass: "value"
  }, [_vm.fueling.provider ? _c('crm-company-label', {
    attrs: {
      "companyType": "provider",
      "integrated": _vm.fueling.provider.company
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Raportujący")]), _c('div', {
    staticClass: "value"
  }, [_c('user-label', {
    attrs: {
      "withAvatar": "",
      "data": _vm.fueling.reporter
    }
  })], 1)]), _c('div', {
    staticClass: "field"
  }, [_c('div', {
    staticClass: "label"
  }, [_vm._v("Uwagi")]), _c('div', {
    staticClass: "value",
    domProps: {
      "innerHTML": _vm._s(_vm.fueling.comment)
    }
  })])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }