<template>
  <div class="investments-fueling-table">
    <vs-table showActionButton
      mode="remote"
      :columns="columns"
      :rows="$store.state.investments.fueling.fueling"
      :totalRecords="table.totalRecords"
      :current-page="table.page"
      :checkSort="getColumnSortType"
      :perPage="table.perPage"
      :showSearch="false"
      :searchTerm="searchTerm"
      :actionItems="actionItems"
      :isLoading="table.isLoading"
      @on-row-click="openDetails"
      @searchTermChange="(term) => updateSearchTerm(term)"
      @on-page-change="onPageChange"
      @on-per-page-change="onPageChange"
      @on-sort-change="onSortChange"
    >
      <template v-slot:row="{ column, row }" >
        <span v-if="column.field === 'date'">
          {{ formatDate.investmentFuel(row.order_date) }}
        </span>
        <span v-else-if="column.field === 'provider'">
          <crm-company-label
            companyType="provider"
            :integrated="row.provider.company"
          />
        </span>
        <span v-else-if="column.field === 'amount'">
          {{ row.amount_fuel.toLocaleString() }} l
        </span>
        <span v-else-if="column.field === 'reporter'">
          <user-label :data="row.reporter" withAvatar />
        </span>
        <span v-else-if="column.field === 'investment'">
          <investments-investment-label
            :investment="row.investment"
          />
        </span>
      </template>
    </vs-table>

    <investments-fueling-details-modal
      @delete="deleteFueling"
      @edit="openEditModal"
    />
    <investments-edit-fueling-modal />

  </div>
</template>

<script>

import VsTable from '@/components/ui/vs-table/Table.vue';
import InvestmentsFuelingTable from '@/hooks/tables/investments/investmentsFueling';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import { DeleteFuelingError, DeleteFuelingSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { deleteModal } from '@/libs/modals';
import formatDate from '@/libs/date-formatter';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import useInvestmentsModals from '@/hooks/investments/useInvestmentsModals';
import InvestmentsInvestmentLabel from './InvestmentsInvestmentLabel.vue';
import InvestmentsFuelingDetailsModal from './modals/fueling/InvestmentsFuelingDetailsModal.vue';
import InvestmentsEditFuelingModal from './modals/fueling/InvestmentsEditFuelingModal.vue';

export default {
  name: 'InvestmentsListTable',
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, rows, changeSorting, getColumnSorting,
    } = InvestmentsFuelingTable.value;
    const {
      modalEditFueling,
      modalFuelingDetails,
    } = useInvestmentsModals();

    const deleteFueling = async (obj) => {
      const confirmation = await deleteModal({
        title: i18n.t('investments.modal.fueling.delete.title'),
        text: i18n.t('investments.modal.fueling.delete.text', {
          date: obj.order_date,
        }),
      });

      if (!confirmation) return false;

      const request = useInvestmentsApi()
        .deleteFueling(obj.id)
        .then(() => {
          showToast(DeleteFuelingSuccess);
          modalFuelingDetails.value.close();
          fetchData();
        })
        .catch(() => {
          showToast(DeleteFuelingError);
        });

      return request;
    };

    const openEditModal = (obj) => {
      modalFuelingDetails.value.close();
      modalEditFueling.value.open(obj);
    };

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          openEditModal(obj);
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        callback: (obj) => {
          deleteFueling(obj);
        },
      },
    ];

    const onSortChange = (data) => {
      const { field, type } = data[0];
      changeSorting({ field, type });
    };

    const getColumnSortType = (field) => getColumnSorting(field)?.type;

    const openDetails = (data) => {
      modalFuelingDetails.value.open(data.row);
    };

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      onSortChange,
      formatDate,
      getColumnSortType,
      openDetails,
      deleteFueling,
      openEditModal,
    };
  },
  components: {
    VsTable,
    CrmCompanyLabel,
    InvestmentsInvestmentLabel,
    UserLabel,
    InvestmentsFuelingDetailsModal,
    InvestmentsEditFuelingModal,
  },
};
</script>

<style lang="sass" scoped>
.work-type-item
  &:not(:last-child):after
    margin-left: -3px
    content: ', '
</style>
