<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.dateFrom')"
            >
            <vs-datepicker
              placeholder="Wybierz datę początkową"
              v-model="dateFrom"
              reset-button
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.global.field.dateTo')"
            >
            <vs-datepicker
              placeholder="Wybierz datę końcową"
              v-model="dateTo"
              reset-button
            />
          </b-form-group>
        </b-col>

        <b-col cols="4">
          <b-form-group
            label="Nazwa inwestycji"
            >
            <investments-investment-picker
              multiple
              :reduce="i => i.id"
              v-model="filters.investment_ids"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <b-row>

        <b-col cols="4">
          <b-form-group
            :label="$t('base.machines.mth.field.reporter')"
          >
            <core-user-picker
              withDeleted
              multiple
              v-model="filters.reporter_ids"
            />
          </b-form-group>
        </b-col>

      </b-row>

      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>

    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup,
} from 'bootstrap-vue';
import investmentsReports from '@/hooks/tables/investments/investmentsReports';
import formatDate from '@/libs/date-formatter';
import { computed } from 'vue';
import { useRouter } from '@/@core/utils/utils';
import router from '@/router';
import InvestmentsInvestmentPicker from '@/components/module/investments/InvestmentsInvestmentPicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';

export default {
  name: 'InvestmentsReportsFiltration',
  setup() {
    const {
      table, filters, clearAllFilters, fetchData,
    } = investmentsReports.value;

    const { route } = useRouter();

    const getIdFilterFromRoute = (param) => {
      const routeFilter = route.value.params?.[param];
      if (!routeFilter) return [];

      return [Number(routeFilter)];
    };

    // filters are defined as a ref, why can't we access `value` property?
    // FIXME: find out why this happens
    investmentsReports.value.shouldUpdateTableOnFilterChange = false;
    filters.investment_ids = getIdFilterFromRoute('investmentId');

    // FIXME: variable is also defined as a ref, but can't access
    // the `value` property. Also get rid of this timeout workaround
    setTimeout(() => {
      investmentsReports.value.shouldUpdateTableOnFilterChange = true;
      fetchData();
    }, 0);

    const clearFiltersAndChangeRoute = () => {
      router.push({ name: route.value.name, params: {} });
      clearAllFilters();
    };

    const dateFrom = computed({
      get() {
        return formatDate.standard(filters.date_from);
      },
      set(newDate) {
        filters.date_from = newDate;
      },
    });

    const dateTo = computed({
      get() {
        return formatDate.standard(filters.date_to);
      },
      set(newDate) {
        filters.date_to = newDate;
      },
    });

    return {
      clearAllFilters: clearFiltersAndChangeRoute,
      table,
      dateFrom,
      dateTo,
      filters,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    CoreUserPicker,
    InvestmentsInvestmentPicker,
    VsDatepicker,
  },
};
</script>
