var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-fueling-table"
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.investments.fueling.fueling,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": _vm.getColumnSortType,
      "perPage": _vm.table.perPage,
      "showSearch": false,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'date' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.investmentFuel(row.order_date)) + " ")]) : column.field === 'provider' ? _c('span', [_c('crm-company-label', {
          attrs: {
            "companyType": "provider",
            "integrated": row.provider.company
          }
        })], 1) : column.field === 'amount' ? _c('span', [_vm._v(" " + _vm._s(row.amount_fuel.toLocaleString()) + " l ")]) : column.field === 'reporter' ? _c('span', [_c('user-label', {
          attrs: {
            "data": row.reporter,
            "withAvatar": ""
          }
        })], 1) : column.field === 'investment' ? _c('span', [_c('investments-investment-label', {
          attrs: {
            "investment": row.investment
          }
        })], 1) : _vm._e()];
      }
    }])
  }), _c('investments-fueling-details-modal', {
    on: {
      "delete": _vm.deleteFueling,
      "edit": _vm.openEditModal
    }
  }), _c('investments-edit-fueling-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }