<template>
  <div class="investments-report-details-modal">
    <vs-modal
      :modalInstance="modalReportDetails"
    >
      <template #default="{ data }">
        <form-steps-indicator
          :current-step="step"
          interactive
          @stepChange="(i) => step = i"
        />

        <div class="details" v-if="data && data.brigade && step === 0">
          <div class="entry">
            <div class="label">Data raportu</div>
            <div class="value">
              {{ formatDate.baseReport(data.daily_balance_date) }}
            </div>
          </div>

          <div class="entry">
            <div class="label">Inwestycja</div>
            <div class="value">
              <investments-investment-label
                :investment="data.brigade.investment"
              />
            </div>
          </div>

          <div class="entry">
            <div class="label">Brygada zarządzająca</div>

            <div class="vehicles" v-if="managementBrigadeVehicles.length">
              <div class="vehicle main">
                Maszyna główna:
                <base-resource-label
                  :resource="getMainVehicleFromArray(managementBrigadeVehicles)"
                />
              </div>
              <div
                class="vehicle"
                v-if="getSecondaryVehiclesFromArray(managementBrigadeVehicles)"
              >
                Zasoby dodatkowe:
                <div
                  class="secondary-vehicle"
                  v-for="vehicle in getSecondaryVehiclesFromArray(managementBrigadeVehicles)"
                  :key="vehicle.id"
                >
                  <base-resource-label
                    :resource="vehicle"
                  />
                </div>
              </div>
            </div>

            <div class="value-employees">
              <vs-loader
                inline
                text="Wczytywanie zawartości"
                v-if="isLoading"
              />
              <template v-else-if="managementEmployees && managementEmployees.length">
                <user-label isEmployee
                  v-for="employee in managementEmployees"
                  :key="employee.id"
                  :data="employee.cadre_employee"
                  showLink
                >
                  <template #extra-info>({{ employee.cadre_position.name }})</template>
                </user-label>
              </template>
            </div>
          </div>

          <div class="entry">
            <div class="label">Raportujący</div>
            <div class="value value-user">
              <user-label
                withAvatar
                :id="data.reporter_id"
              />
            </div>
          </div>

          <div class="entry">
            <div class="label">Praca (od - do)</div>
            <div class="value">
              {{ formatDate.getTime(data.work_scheduler.start_date) }}
              -
              {{ formatDate.getTime(data.work_scheduler.end_date) }}
            </div>
          </div>

          <div class="entry">
            <div class="label">Produkcja (od - do)</div>
            <div class="value">
              {{ formatDate.getTime(data.production_scheduler.start_date) }}
              -
              {{ formatDate.getTime(data.production_scheduler.end_date) }}
            </div>
          </div>

          <div class="entry">
            <div class="label">Brygada</div>
            <div class="value">
              {{ data.brigade.name }}
            </div>

            <div class="vehicles" v-if="assignedBrigadeVehicles.length">
              <div class="vehicle main">
                Maszyna główna:
                <base-resource-label
                  :resource="getMainVehicleFromArray(assignedBrigadeVehicles)"
                />
              </div>
              <div
                class="vehicle"
                v-if="getSecondaryVehiclesFromArray(assignedBrigadeVehicles)"
              >
                Zasoby dodatkowe:
                <div
                  class="secondary-vehicle"
                  v-for="vehicle in getSecondaryVehiclesFromArray(assignedBrigadeVehicles)"
                  :key="vehicle.id"
                >
                  <base-resource-label
                    :resource="vehicle"
                  />
                </div>
              </div>
            </div>

            <div class="value-employees">
              <vs-loader
                inline
                text="Wczytywanie zawartości"
                v-if="isLoading"
              />
              <template v-else-if="assignedEmployees && assignedEmployees.length">
                <user-label isEmployee
                  v-for="employee in assignedEmployees"
                  :key="employee.id"
                  :data="employee.cadre_employee"
                  showLink
                >
                  <template #extra-info>
                    <position-label
                      :id="employee.cadre_position_id"
                    >
                      <template #default="{ label }">
                        ({{ label }})
                      </template>
                    </position-label>
                  </template>
                </user-label>
              </template>
            </div>
          </div>

          <div class="entry">
            <div class="label">Przerób dzienny</div>
            <div class="value-table">
              <vs-loader
                inline
                text="Wczytywanie zawartości"
                v-if="isLoading"
              />
              <investments-report-progress-table
                :progress="reportProgress"
                v-else-if="reportProgress.length"
              />
              <div
                class="info"
                v-else>
                Nie znaleziono zawartości dla wybranego raportu
              </div>
            </div>
          </div>

          <div class="entry">
            <div class="label">Uwagi</div>
            <div class="value">
              {{ data.description || '-' }}
            </div>
          </div>

        </div>

        <investments-report-form-fuel
          disableAdd
          disableEdit
          :reportType="step === 1 ? 'work' : 'fuel'"
          :investment="selectedInvestment"
          :reportDate="data.daily_balance_date"
          @update:investment="updateSelectedInvestment"
          v-else-if="data && data.brigade && (step === 1 || step === 2)"
        />

        <div class="list" v-else>
          <investments-report-form-details
            :investment="selectedInvestment"
            :reportDate="data.daily_balance_date"
          />
          <investments-report-form-list
            disableEdit
            :value="additionalCosts"
            :noteValue="additionalCostsNote"
          />
        </div>
      </template>

    </vs-modal>
  </div>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useInvestmentsModals from '@/hooks/investments/useInvestmentsModals';
import UserLabel from '@/components/ui/UserLabel.vue';
import formatDate from '@/libs/date-formatter';
import { ref, watch } from 'vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import store from '@/store';
import FormStepsIndicator from '@/components/ui/FormStepsIndicator.vue';
import InvestmentsInvestmentLabel from '../InvestmentsInvestmentLabel.vue';
import InvestmentsReportProgressTable from '../InvestmentsReportProgressTable.vue';
import InvestmentsReportFormFuel from '../forms/investments-report-form/InvestmentsReportFormFuel.vue';
import PositionLabel from '../../cadre/ui/PositionLabel.vue';
import InvestmentsReportFormList from '../forms/investments-report-form/InvestmentsReportFormList.vue';
import InvestmentsReportFormDetails from '../forms/investments-report-form/InvestmentsReportFormDetails.vue';
import BaseResourceLabel from '../../base/resource/BaseResourceLabel.vue';

export default {
  name: 'InvestmentsReportDetailsModal',
  setup() {
    const { modalReportDetails } = useInvestmentsModals();
    const isLoading = ref(true);

    store.dispatch('units/fetch');

    const reportProgress = ref([]);
    const managementEmployees = ref([]);
    const additionalCosts = ref([]);
    const additionalCostsNote = ref('');
    const assignedEmployees = ref([]);
    const managementBrigadeVehicles = ref([]);
    const assignedBrigadeVehicles = ref([]);
    const selectedInvestment = ref({});
    const updateSelectedInvestment = (data) => {
      selectedInvestment.value = data;
    };

    const fetchReportProgress = (reportId) => {
      if (!reportId) return;
      useInvestmentsApi()
        .fetchReport(reportId)
        .then(({ data }) => {
          isLoading.value = false;
          reportProgress.value = data.data.daily_balance_progress;
          managementEmployees.value = data.data.brigade?.investment?.management_brigade?.employees;
          assignedEmployees.value = data.data.assigned_employees;
          selectedInvestment.value = data.data.brigade?.investment;
          additionalCosts.value = data.data.additional_costs;
          additionalCostsNote.value = data.data.additional_cost_note;
          assignedBrigadeVehicles.value = data.data.brigade.resource ?? [];
          managementBrigadeVehicles.value = data.data.brigade
            ?.investment?.management_brigade?.resource ?? [];
        })
        .catch(() => {
          isLoading.value = false;
          // TODO: feedback
        });
    };

    const step = ref(0);

    watch(() => modalReportDetails.value.visible, () => {
      if (!modalReportDetails.value.visible) {
        reportProgress.value = [];
        step.value = 0;
        return;
      }

      isLoading.value = true;
      const reportId = modalReportDetails.value?.data?.id;
      fetchReportProgress(reportId);
    });

    const getMainVehicleFromArray = (arr) => arr.find((a) => !!a.pivot.is_main)?.resource;
    const getSecondaryVehiclesFromArray = (arr) => arr
      .filter((a) => !a.pivot.is_main)
      .map((a) => a.resource);

    return {
      modalReportDetails,
      formatDate,
      reportProgress,
      isLoading,
      managementEmployees,
      assignedEmployees,
      step,
      additionalCosts,
      additionalCostsNote,
      selectedInvestment,
      updateSelectedInvestment,
      managementBrigadeVehicles,
      assignedBrigadeVehicles,
      getMainVehicleFromArray,
      getSecondaryVehiclesFromArray,
    };
  },
  components: {
    VsModal,
    InvestmentsInvestmentLabel,
    UserLabel,
    InvestmentsReportProgressTable,
    VsLoader,
    FormStepsIndicator,
    InvestmentsReportFormFuel,
    PositionLabel,
    InvestmentsReportFormList,
    InvestmentsReportFormDetails,
    BaseResourceLabel,
  },
};
</script>

<style lang="sass" scoped>
.value-employees
  padding-top: 10px
  display: flex
  flex-direction: column
  gap: 5px

.list
  padding: 30px

.info
  font-size: 14pt
  font-weight: 500
  opacity: 0.6

.entry
  margin: 20px 0

  .vehicle
    display: flex
    align-items: flex-start
    gap: 10px

  .label
    opacity: 0.7
    display: flex
    align-items: center
    white-space: nowrap
    gap: 10px

    &:after
      width: 100%
      display: block
      height: 1px
      content: ''
      background: var(--vs-card-border)
      flex-shrink: 1

  .value
    font-size: 14pt
    font-weight: 500

    &-table
      margin-top: 10px

    &-user
      margin-top: 10px
</style>
