var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-processing-summary"
  }, [_c('table', [_c('tr', [_c('td', [_vm._v("inwestycja")]), _c('td', [_c('investments-investment-label', {
    attrs: {
      "investment": _vm.investment,
      "id": _vm.investment.id,
      "target": "_blank"
    }
  })], 1)]), _c('tr', [_c('td', [_vm._v("grupa robót")]), _c('td', [_c('div', {
    staticClass: "item-list"
  }, _vm._l(_vm.investment.work_groups, function (group) {
    return _c('div', {
      key: `group-${group.id}`,
      staticClass: "item"
    }, [_vm._v(_vm._s(group.name))]);
  }), 0)])]), _c('tr', [_c('td', [_vm._v("typ robót")]), _c('td', [_c('div', {
    staticClass: "item-list"
  }, _vm._l(_vm.investment.work_types, function (type) {
    return _c('div', {
      key: `type-${type.id}`,
      staticClass: "item"
    }, [_vm._v(_vm._s(type.name))]);
  }), 0)])]), _c('tr', [_c('td', [_vm._v("przedmiot zamówienia")]), _c('td', [_c('div', {
    staticClass: "item-list"
  }, _vm._l(_vm.investment.subjects, function (subject) {
    return _c('div', {
      key: `subject-${subject.id}`,
      staticClass: "item"
    }, [_vm._v(_vm._s(subject.technology.name) + " Ø" + _vm._s(Math.floor(subject.diameter)) + "mm")]);
  }), 0)])]), _c('tr', [_c('td', [_vm._v("liczba pali")]), _c('td', [_vm._v(" " + _vm._s(_vm.investment.subjects.reduce(function (acc, _ref) {
    var quantity = _ref.quantity;
    return acc + quantity;
  }, 0)) + " ")])]), _c('tr', [_c('td', [_vm._v("zamawiający")]), _c('td', [_c('crm-company-label', {
    attrs: {
      "integrated": _vm.investment.principal
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }