<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="3">
          <b-form-group
            label="Data dostawy od"
            >
            <vs-datepicker
              placeholder="Wybierz datę początkową"
              v-model="filters.date_from"
              reset-button
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Data dostawy do"
            >
            <vs-datepicker
              placeholder="Wybierz datę końcową"
              v-model="filters.date_to"
              reset-button
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Inwestycja"
          >
            <investments-investment-picker
              multiple
              v-model="filters.investment_id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Dostawca"
            >
            <crm-provider-picker
              multiple
              v-model="filters.provider_id"
              :reduce="o => o.id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Raportujący"
            >
            <core-user-picker
              withDeleted
              multiple
              v-model="filters.reporting_id"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup,
} from 'bootstrap-vue';
import investmentsFuelingTable from '@/hooks/tables/investments/investmentsFueling';
import InvestmentsInvestmentPicker from '@/components/module/investments/InvestmentsInvestmentPicker.vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';
import CoreUserPicker from '@/components/module/core/CoreUserPicker.vue';
import CrmProviderPicker from '@/components/module/crm/CrmProviderPicker.vue';
import { useRouter } from '@/@core/utils/utils';

export default {
  name: 'InvestmentsFuelingFiltration',
  setup() {
    const {
      table, filters, clearAllFilters, fetchData,
    } = investmentsFuelingTable.value;

    const { route } = useRouter();

    const getIdFilterFromRoute = (param) => {
      const routeFilter = route.value.params?.[param];
      if (!routeFilter) return [];

      return [Number(routeFilter)];
    };

    // filters are defined as a ref, why can't we access `value` property?
    // FIXME: find out why this happens
    investmentsFuelingTable.value.shouldUpdateTableOnFilterChange = false;
    filters.investment_id = getIdFilterFromRoute('investmentId');

    // FIXME: variable is also defined as a ref, but can't access
    // the `value` property. Also get rid of this timeout workaround
    setTimeout(() => {
      investmentsFuelingTable.value.shouldUpdateTableOnFilterChange = true;
      fetchData();
    }, 0);

    return {
      filters,
      clearAllFilters,
      table,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,
    InvestmentsInvestmentPicker,
    CrmProviderPicker,
    VsDatepicker,
    CoreUserPicker,
  },
};
</script>
