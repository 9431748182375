var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-processing-table"
  }, [_c('div', {
    staticClass: "filters"
  }, [_c('div', {
    staticClass: "picker-wrapper"
  }, [_c('investments-investment-picker', {
    attrs: {
      "reduce": function (investment) {
        return investment.id;
      },
      "placeholder": "Wybierz inwestycję"
    },
    model: {
      value: _vm.pickedInvestment,
      callback: function ($$v) {
        _vm.pickedInvestment = $$v;
      },
      expression: "pickedInvestment"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "filter-dropdown",
    attrs: {
      "dropleft": "",
      "no-caret": "",
      "size": "sm"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "MenuIcon"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-form', [_c('div', {
    staticClass: "checkbox-group-wrapper"
  }, [_c('b-form-checkbox-group', {
    attrs: {
      "stacked": "",
      "options": _vm.allFields,
      "value-field": "field",
      "text-field": "name"
    },
    model: {
      value: _vm.enabledColumns,
      callback: function ($$v) {
        _vm.enabledColumns = $$v;
      },
      expression: "enabledColumns"
    }
  })], 1)])], 1)], 1), _vm.investmentData ? _c('investments-processing-summary', {
    attrs: {
      "investment": _vm.investmentData
    }
  }) : _vm._e(), _c('div', {
    staticClass: "table-wrapper"
  }, [_c('table', [_c('thead', [_c('tr', _vm._l(_vm.computedTable, function (head, idx) {
    return _c('th', {
      key: `head-${idx}`,
      attrs: {
        "rowspan": head.rowspan,
        "colspan": head.colspan
      }
    }, [_vm._v(" " + _vm._s(head.name) + " ")]);
  }), 0), _c('tr', _vm._l(_vm.computedChildren, function (head, idx) {
    return _c('th', {
      key: `head-c-${idx}`
    }, [_vm._v(" " + _vm._s(head.name) + " ")]);
  }), 0)]), _c('tbody', [_vm._l(_vm.computedRows, function (row, idx) {
    return _c('tr', {
      key: `report-row-${idx}`
    }, _vm._l(row, function (td, tidx) {
      var _td$value;
      return _c('td', {
        key: `report-row-${idx}-${tidx}`
      }, [td.name === 'day' ? [_vm._v(" " + _vm._s(td.value) + " ")] : td.name === 'date' ? [_vm._v(" " + _vm._s(_vm.formatDate.training(td.value)) + " (" + _vm._s(_vm.getWeekday(td.value)) + ") ")] : td.name === 'resource' ? [_c('base-resource-label', {
        attrs: {
          "id": (_td$value = td.value) === null || _td$value === void 0 ? void 0 : _td$value.id,
          "resource": td.value
        }
      })] : td.name === 'diameter' ? [_vm._v(" " + _vm._s(td.value) + " mm ")] : td.name === 'subject.done_percentage' ? [_vm._v(" " + _vm._s(td.value) + "% ")] : td.name === 'average_performance' ? [_vm._v(" " + _vm._s(td.value) + " szt. ")] : td.name.startsWith('subject') ? [_vm._v(" " + _vm._s(td.value) + " szt. ")] : td.name.startsWith('current_meter') ? [_vm._v(" " + _vm._s(td.value) + " m ")] : td.name === 'usages.0.real_usage' ? [_vm._v(" " + _vm._s(td.value) + " % ")] : td.name === 'usages.0.real_usage_sum' ? [_vm._v(" " + _vm._s(td.value) + " % ")] : td.name.startsWith('usages') ? [_vm._v(" " + _vm._s(td.value) + " m3 ")] : [_vm._v(" " + _vm._s(td.value) + " ")]], 2);
    }), 0);
  }), _vm.computedRows.length === 0 ? _c('tr', [_c('td', {
    staticClass: "no-data-info",
    attrs: {
      "colspan": _vm.allFields.length
    }
  }, [_vm._v(" Brak danych dla wybranej inwestycji ")])]) : _vm._e()], 2)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }