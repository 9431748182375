<template>
  <div class="investments-investment-form">
    <vs-form
      v-model="investment"
      :errors="errors"
      i18nNamespace="investments.form.investment.field"
    >

      <vs-form-group
        field="name"
        cols="9"
        labelCols="4"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.field.name')"
            v-model="investment.name"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="shortcut"
        noLabel
        cols="3"
        >
        <b-input-group>
          <b-form-input
            :placeholder="$t('investments.form.investment.field.shortcut')"
            v-model="investment.shortcut"
          />
        </b-input-group>
      </vs-form-group>

      <vs-form-group
        field="core_branch_id"
        >
        <core-branch-picker
          v-model="investment.core_branch_id"
        />
      </vs-form-group>

      <vs-form-group
        field="crm_principal_id"
        integration="crm_address_relation"
        >
        <crm-client-picker
          v-model="investment.crm_principal_id"
          :reduce="company => company.id"
        />
      </vs-form-group>

      <vs-form-group
        field="investment_status_id"
        >
        <investments-status-picker
          autopick
          v-model="investment.investment_status_id"
        />
      </vs-form-group>

      <vs-form-group
        field="serial"
        >
        <div class="editable-spans">
          <span
            contenteditable
            spellcheck="false"
            @blur="(e) => updateSerialKey(e, 'id')"
          >{{ serial.id }}</span>

          <span>/{{ serial.branch || 'X' }}/</span>

          <span
            contenteditable
            spellcheck="false"
            @blur="(e) => updateSerialKey(e, 'year')"
          >{{ serial.year }}</span>

          <span>/{{ serial.shortcut || 'X' }}/</span>

          <span
            contenteditable
            spellcheck="false"
            @blur="(e) => updateSerialKey(e, 'provider')"
          >{{ serial.provider }}</span>
        </div>
      </vs-form-group>

    </vs-form>
  </div>
</template>

<script>
import {
  BInputGroup,
  BFormInput,
} from 'bootstrap-vue';
import {
  computed, reactive, ref, watch,
} from 'vue';
import VsForm from '@/components/ui/vs-form/VsForm.vue';
import VsFormGroup from '@/components/ui/vs-form/VsFormGroup.vue';
import store from '@/store';
import CrmClientPicker from '@/components/module/crm/CrmClientPicker.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import InvestmentsStatusPicker from '@/components/module/investments/InvestmentsStatusPicker.vue';

export default {
  name: 'InvestmentsCreateForm',
  props: {
    value: {
      type: Object,
      default: () => ({}),
    },
    feedback: {
      type: Object,
      default: () => ({}),
    },
  },
  setup(props, { emit }) {
    store.dispatch('branches/fetch');
    const investment = computed(() => props.value);

    const errors = ref({});
    watch(() => props.feedback, () => { errors.value = props.feedback; });

    const serial = reactive({
      id: null,
      branch: null,
      year: new Date().getFullYear(),
      shortcut: null,
      provider: null,
    });

    watch(serial, () => {
      const {
        id, branch, year, shortcut, provider,
      } = serial;

      const serialNumber = `${id}/${branch}/${year}/${shortcut}/${provider}`;

      emit('updateSerial', serialNumber);
    });

    watch(() => investment.value.core_branch_id, () => {
      const branch = store.getters['branches/byId'](investment.value.core_branch_id);
      serial.branch = branch?.shortcut;
    });

    watch(() => investment.value.shortcut, () => {
      serial.shortcut = investment.value.shortcut;
    });

    const updateSerialKey = (e, key) => {
      e.preventDefault();
      serial[key] = e.target.innerHTML;
      // eslint-disable-next-line no-param-reassign
      e.target.innerHTML = serial[key];
    };

    return {
      investment,
      errors,
      serial,
      updateSerialKey,
    };
  },
  components: {
    BInputGroup,
    BFormInput,
    VsForm,
    VsFormGroup,
    CoreBranchPicker,
    CrmClientPicker,
    InvestmentsStatusPicker,
  },
};
</script>

<style lang="sass" scoped>
.editable-spans
  display: flex
  margin-top: 5px
  font-size: 12pt

  input
    width: 1em

  span
    display: block
    position: relative
    opacity: 0.7
    min-width: 10px

    &:empty
      min-width: 50px
      background: rgba(128, 128, 128, 0.1)
      border-radius: 5px

    &[contenteditable="true"]
      opacity: 1
      font-weight: 400

      &:focus
        outline: none
        font-weight: 500

      &:not(:empty):after
        left: 0
        display: block
        content: ''
        position: absolute
        background: orange
        width: 100%
        height: 1px

.serial-persistent
  text-align: center
  width: 10px
</style>
