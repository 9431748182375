<template>
  <div class="investments-list">

    <b-tabs
      @activate-tab="changeTab"
    >

      <b-tab
        lazy
        :active="isTabActive('investments')"
      >
        <template #title>
          <feather-icon icon="CompassIcon" />
          <span>Inwestycje</span>
        </template>

        <b-card>
          <authorization-guard
            permission="investment.investment.index"
            silent
          >
            <investments-list-filtration />
          </authorization-guard>

          <div class="buttons">
            <authorization-guard
              permission="investment.investment.store"
              silent
            >
              <vs-button
                @click="investmentCreateModal = true"
                vs-variant="primary"
              >
                + {{ $t('investments.button.addInvestment') }}
              </vs-button>
          </authorization-guard>
          </div>
          <br>

          <authorization-guard
            permission="investment.investment.index"
          >
            <investments-list-table />
          </authorization-guard>
        </b-card>
      </b-tab>

      <b-tab
        lazy
        :active="isTabActive('reports')"
      >
        <template #title>
          <feather-icon icon="ActivityIcon" />
          <span>Raporty</span>
        </template>

        <investments-reports />
      </b-tab>

      <b-tab
        lazy
        :active="isTabActive('processing')"
      >
        <template #title>
          <feather-icon icon="BarChart2Icon" />
          <span>Przeroby</span>
        </template>

        <investments-processing />
      </b-tab>

      <b-tab
        lazy
        :active="isTabActive('fueling')"
      >
        <template #title>
          <feather-icon icon="DropletIcon" />
          <span>Paliwo</span>
        </template>

        <b-card>
          <authorization-guard
            permission="investment.fuel.index"
            silent
          >
            <investments-fueling-filtration />
          </authorization-guard>

          <authorization-guard
            permission="investment.fuel.store"
            silent
          >
            <vs-button
              vs-variant="primary"
              class="mb-2"
              @click="openFuelingModal"
            >
              + Dodaj dostawę
            </vs-button>
          </authorization-guard>

          <authorization-guard
            permission="investment.fuel.index"
          >
            <investments-fueling-table />
          </authorization-guard>

          <investments-create-fueling-modal
            @success="fetchData"
          />
        </b-card>
      </b-tab>

    </b-tabs>

    <investments-create-modal />
    <investments-report-modal />
  </div>
</template>

<script>
import InvestmentsListTable from '@/components/views/investments/InvestmentsListTable.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import InvestmentsListFiltration from '@/components/views/investments/InvestmentsListFiltration.vue';
import {
  BTabs,
  BTab,
  BCard,
} from 'bootstrap-vue';
import InvestmentsCreateModal from '@/components/views/investments/modals/InvestmentsCreateModal.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import InvestmentsReportModal from '@/components/views/investments/modals/InvestmentsReportModal.vue';
import { computed } from 'vue';
import { useRouter } from '@/@core/utils/utils';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';
import InvestmentsFuelingTable from '@/components/views/investments/InvestmentsFuelingTable.vue';
import InvestmentsFuelingFiltration from '@/components/views/investments/InvestmentsFuelingFiltration.vue';
import useInvestmentsModals from '@/hooks/investments/useInvestmentsModals';
import InvestmentsCreateFuelingModal from '@/components/views/investments/modals/fueling/InvestmentsCreateFuelingModal.vue';
import investmentsFueling from '@/hooks/tables/investments/investmentsFueling';
import useTaskStatusStore from '@/stores/module/tasks/useTaskStatusStore';
import InvestmentsReports from './InvestmentsReports.vue';
import InvestmentsProcessing from './InvestmentsProcessing.vue';

export default {
  name: 'InvestmentsList',
  setup() {
    const { investmentCreateModal } = useInvestmentsStore();
    const { route, router } = useRouter();

    const activeTab = computed(() => route.value.meta?.activeTab);
    const isTabActive = (tabName) => activeTab.value === tabName;

    const tabRoutes = {
      0: 'investments',
      1: 'investments.reports',
      2: 'investments.processing',
      3: 'investments.fueling',
    };

    const changeTab = (to) => {
      const desiredRoute = tabRoutes?.[to];

      if (!desiredRoute) return;
      if (desiredRoute === route.value.name) return;

      router.push({
        name: desiredRoute,
      });
    };

    const { modalCreateFueling } = useInvestmentsModals();
    const openFuelingModal = () => {
      modalCreateFueling.value.open();
    };

    const { fetchData } = investmentsFueling.value;

    const statusStore = useTaskStatusStore();

    return {
      investmentCreateModal,
      isTabActive,
      changeTab,
      openFuelingModal,
      fetchData,
      statusStore,
    };
  },
  components: {
    BCard,
    BTabs,
    BTab,

    InvestmentsListFiltration,
    InvestmentsListTable,
    VsButton,
    InvestmentsCreateModal,
    InvestmentsReports,
    InvestmentsReportModal,
    AuthorizationGuard,
    InvestmentsProcessing,
    InvestmentsFuelingTable,
    InvestmentsFuelingFiltration,
    InvestmentsCreateFuelingModal,
  },
};
</script>
