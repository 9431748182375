<template>
  <div class="investments-processing-summary">
    <table>

      <tr>
        <td>inwestycja</td>
        <td>
          <investments-investment-label
            :investment="investment"
            :id="investment.id"
            target="_blank"
          />
        </td>
      </tr>

      <tr>
        <td>grupa robót</td>
        <td>
          <div class="item-list">
            <div
              class="item"
              v-for="group in investment.work_groups"
              :key="`group-${group.id}`"
            >{{ group.name }}</div>
          </div>
        </td>
      </tr>

      <tr>
        <td>typ robót</td>
        <td>
          <div class="item-list">
            <div
              class="item"
              v-for="type in investment.work_types"
              :key="`type-${type.id}`"
            >{{ type.name }}</div>
          </div>
        </td>
      </tr>

      <tr>
        <td>przedmiot zamówienia</td>
        <td>
          <div class="item-list">
            <div
              class="item"
              v-for="subject in investment.subjects"
              :key="`subject-${subject.id}`"
            >{{ subject.technology.name }} Ø{{ Math.floor(subject.diameter) }}mm</div>
          </div>
        </td>
      </tr>

      <tr>
        <td>liczba pali</td>
        <td>
          {{ investment.subjects.reduce((acc, { quantity }) => acc + quantity, 0)}}
        </td>
      </tr>

      <tr>
        <td>zamawiający</td>
        <td>
          <crm-company-label :integrated="investment.principal" />
        </td>
      </tr>

    </table>
  </div>
</template>

<script>
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import InvestmentsInvestmentLabel from './InvestmentsInvestmentLabel.vue';

export default {
  components: { InvestmentsInvestmentLabel, CrmCompanyLabel },
  name: 'InvestmentsProcessingSummary',
  props: {
    investment: {
      type: Object,
      default: () => {},
    },
  },
};
</script>

<style lang="sass" scoped>
.investments-processing-summary
  table
    td
      &:first-child
        padding-right: 20px

  .item-list
    display: flex
    gap: 5px

    .item
      &:not(:last-child):after
        margin: 0
        content: ', '
</style>
