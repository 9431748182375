var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-processing"
  }, [_c('b-card', [_vm.isLoading ? _c('vs-loader', {
    staticStyle: {
      "top": "0"
    },
    attrs: {
      "full": "",
      "text": "Pobieranie danych"
    }
  }) : _vm._e(), _c('investments-processing-table', {
    attrs: {
      "selectedInvestment": _vm.pickedInvestment,
      "investmentData": _vm.fetchedInvestmentData,
      "rows": _vm.workReport
    },
    on: {
      "investment:update": function (val) {
        return _vm.pickedInvestment = val;
      }
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }