var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "card shadow-none bg-transparent border-secondary"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h4', {
    staticClass: "card-title"
  }, [_vm._v(" " + _vm._s(_vm.$t('global.title.filtering')) + " ")]), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.dateFrom')
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę początkową",
      "reset-button": ""
    },
    model: {
      value: _vm.dateFrom,
      callback: function ($$v) {
        _vm.dateFrom = $$v;
      },
      expression: "dateFrom"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.global.field.dateTo')
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę końcową",
      "reset-button": ""
    },
    model: {
      value: _vm.dateTo,
      callback: function ($$v) {
        _vm.dateTo = $$v;
      },
      expression: "dateTo"
    }
  })], 1)], 1), _c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": "Nazwa inwestycji"
    }
  }, [_c('investments-investment-picker', {
    attrs: {
      "multiple": "",
      "reduce": function (i) {
        return i.id;
      }
    },
    model: {
      value: _vm.filters.investment_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "investment_ids", $$v);
      },
      expression: "filters.investment_ids"
    }
  })], 1)], 1)], 1), _c('b-row', [_c('b-col', {
    attrs: {
      "cols": "4"
    }
  }, [_c('b-form-group', {
    attrs: {
      "label": _vm.$t('base.machines.mth.field.reporter')
    }
  }, [_c('core-user-picker', {
    attrs: {
      "withDeleted": "",
      "multiple": ""
    },
    model: {
      value: _vm.filters.reporter_ids,
      callback: function ($$v) {
        _vm.$set(_vm.filters, "reporter_ids", $$v);
      },
      expression: "filters.reporter_ids"
    }
  })], 1)], 1)], 1), _c('a', {
    attrs: {
      "href": "#"
    },
    on: {
      "click": _vm.clearAllFilters
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('global.table.clearAllFilters')) + " ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }