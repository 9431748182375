<template>
  <div class="investments-report-edit-modal">
    <b-modal
      id="modal-investments-report"
      :title="$t('investments.modal.report.edit.title')"
      cancel-variant="outline-secondary"
      :cancel-title="$t('investments.modal.report.edit.cancel')"
      :ok-title="$t('investments.modal.report.edit.confirm')"
      v-model="investmentEditReportModal.visible"
      size="lg"
      @ok.prevent="summarizeStep"
      no-close-on-backdrop
    >
      <vs-loader full
        text="Aktualizowanie raportu..."
        v-if="processing"
      />

      <form-steps-indicator
        :current-step="step"
        interactive
        @stepChange="(i) => step = i"
      />

      <investments-report-edit-form
        v-model="fields"
        :feedback="feedback"
        v-if="step === 0"
        @additional_costs:update="updateAdditionalCosts"
        @additional_cost_note:update="updateAdditionalCostsNote"
        @usageUpdate="(u) => usage = u"
        @investmentUpdate="updateSelectedInvestment"
      />

      <investments-report-form-fuel
        reportType="work"
        :investment="selectedInvestment"
        :reportDate="`${fields.daily_balance_date} ${fields.report_time}`"
        @update:investment="updateSelectedInvestment"
        v-else-if="step === 1"
      />

      <investments-report-form-fuel
        reportType="fuel"
        :investment="selectedInvestment"
        :reportDate="`${fields.daily_balance_date} ${fields.report_time}`"
        @update:investment="updateSelectedInvestment"
        v-else-if="step === 2"
      />

      <investments-report-form-list
        v-else-if="step === 3"
        :value="additionalCosts"
        :noteValue="additionalCostsNote"
        @list:update="updateAdditionalCosts"
        @note:update="updateAdditionalCostsNote"
      />

      <template #modal-footer="{ ok, cancel }">
        <vs-modal-footer
          disable-on-loading
          :is-loading="processing"

          :text-cancel="$t('investments.modal.report.edit.cancel')"
          :text-ok="$t('investments.modal.report.edit.confirm')"

          @cancel="cancel"
          @ok="ok"
        />
      </template>
    </b-modal>
  </div>
</template>

<script>
import VsLoader from '@/components/ui/vs-loader/VsLoader.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import { computed, ref, watch } from 'vue';
import useValidatedForm from '@/hooks/useValidatedForm';
import formatDate from '@/libs/date-formatter';
import VsModalFooter from '@/components/ui/vs-modal/VsModalFooter.vue';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import showToast from '@/libs/toasts';
import {
  UpdateInvestmentReportError, UpdateInvestmentReportSuccess,
} from '@/libs/toasts/feedback/module/investments';
import investmentsReports from '@/hooks/tables/investments/investmentsReports';
import FormStepsIndicator from '@/components/ui/FormStepsIndicator.vue';
import InvestmentsReportEditForm from '../forms/investments-report-form/InvestmentsReportEditForm.vue';
import InvestmentsReportFormFuel from '../forms/investments-report-form/InvestmentsReportFormFuel.vue';
import InvestmentsReportFormList from '../forms/investments-report-form/InvestmentsReportFormList.vue';

export default {
  name: 'InvestmentsReportEditModal',
  emits: ['success'],
  setup(_, { emit }) {
    const { investmentEditReportModal } = useInvestmentsStore();

    const selectedInvestment = ref(null);
    const updateSelectedInvestment = (value) => {
      selectedInvestment.value = value;
    };

    const processing = ref(false);
    const usage = ref([]);

    const usageWithZeros = computed(() => usage.value.map((u) => {
      const propertyWhitelist = [
        'current_meter', 'investment_subject_id', 'quantity', 'project_current_meter',
      ];
      const zeroed = {};

      Object.keys(u).forEach((property) => {
        if (property === 'daily_balance_usage') {
          zeroed[property] = u[property].map((up) => {
            const quantity = up.quantity === null ? 0 : up.quantity.replace(',', '.');
            return {
              investment_requirement_group_id: up.investment_requirement_group_id,
              quantity,
            };
          });
        } else if (propertyWhitelist.includes(property)) {
          zeroed[property] = u[property] === null ? 0 : u[property];

          if (typeof zeroed[property] === 'string') {
            zeroed[property] = zeroed[property].replace(',', '.');
          }
        }
      });

      return zeroed;
    }));

    const {
      fields, feedback, assignFields, assignServerSideErrors, resetFields,
    } = useValidatedForm({
      id: 'required',
      reporter_id: 'required',
      investment_id: 'required',
      work_time_from: 'required',
      work_time_to: 'required',
      production_time_from: 'required',
      production_time_to: 'required',
      report_time: 'required',
      daily_balance_date: 'required',
      investment_brigade_id: 'required',
      assigned_employees: 'required',
      description: '',
      additional_costs: '',
      additional_cost_note: '',
    }, 'investments.form.report.field');

    const step = ref(0);

    watch(() => investmentEditReportModal.value.visible, () => {
      fields.value = {};
      step.value = 0;
      resetFields();

      const { data } = investmentEditReportModal.value;

      if (data && Object.keys(data).length === 0) return;

      const editingValues = { ...investmentEditReportModal.value.data };
      assignFields({
        ...editingValues,
        work_time_from: formatDate.getTime(editingValues.work_scheduler.start_date),
        work_time_to: formatDate.getTime(editingValues.work_scheduler.end_date),
        production_time_from: formatDate.getTime(editingValues.production_scheduler.start_date),
        production_time_to: formatDate.getTime(editingValues.production_scheduler.end_date),
        report_time: formatDate.getTime(editingValues.daily_balance_date),
        daily_balance_date: formatDate.standard(editingValues.daily_balance_date),
        investment_id: editingValues.brigade.investment_id,
        investment_brigade_id: editingValues.brigade.id,
        assigned_employees: [],
      });

      investmentEditReportModal.value.data = {};
    });

    const timeToDate = (time) => `${fields.value.daily_balance_date} ${time}`;

    const sendReport = () => {
      useInvestmentsApi()
        .updateReport(fields.value.id, {
          investment_brigade_id: fields.value.investment_brigade_id,
          reporter_id: fields.value.reporter_id,
          description: fields.value.description,
          daily_balance_progress: usageWithZeros.value,
          work_scheduler: {
            start_date: timeToDate(fields.value.work_time_from),
            end_date: timeToDate(fields.value.work_time_to),
          },
          production_scheduler: {
            start_date: timeToDate(fields.value.production_time_from),
            end_date: timeToDate(fields.value.production_time_to),
          },
          daily_balance_date: timeToDate(fields.value.report_time),
          assigned_employees: fields.value.assigned_employees,
        })
        .then(() => {
          showToast(UpdateInvestmentReportSuccess);
          investmentEditReportModal.value.visible = false;
          investmentsReports.value.fetchData();
          emit('success');
        })
        .catch(({ response }) => {
          const { errors } = response?.data ?? {};

          assignServerSideErrors({
            ...errors,
            work_time_from: errors?.['work_scheduler.start_date'],
            work_time_to: errors?.['work_scheduler.end_date'],
            production_time_from: errors?.['production_scheduler.start_date'],
            production_time_to: errors?.['production_scheduler.end_date'],
          });
          showToast(UpdateInvestmentReportError);
        });
    };

    const additionalCosts = ref(fields.value.additional_costs);
    const updateAdditionalCosts = (list) => {
      additionalCosts.value = list.map((e) => ({
        name: e.name,
        core_unit_id: e.core_unit_id,
        price: e.price,
        amount: e.amount,
      }));
    };

    const additionalCostsNote = ref(fields.value.additional_cost_note);
    const updateAdditionalCostsNote = (note) => {
      additionalCostsNote.value = note;
    };

    const sendCosts = () => {
      useInvestmentsApi().addReportAdditionalCosts(fields.value.id, {
        additional_costs: additionalCosts.value,
        additional_cost_note: additionalCostsNote.value,
      })
        .then(() => {
          investmentEditReportModal.value.visible = false;
        });
    };

    const summarizeStep = () => {
      if (step.value === 0) sendReport();
      else if (step.value === 3) sendCosts();
      else step.value += 1;
    };

    return {
      fields,
      feedback,
      investmentEditReportModal,
      processing,
      sendReport,
      usage,
      summarizeStep,
      updateSelectedInvestment,
      step,
      selectedInvestment,
      additionalCosts,
      additionalCostsNote,
      updateAdditionalCosts,
      updateAdditionalCostsNote,
    };
  },
  components: {
    VsLoader,
    VsModalFooter,
    InvestmentsReportEditForm,
    FormStepsIndicator,
    InvestmentsReportFormFuel,
    InvestmentsReportFormList,
  },
};

</script>

<style lang="sass">
#modal-investments-report
  .modal-dialog
    max-width: 70% !important
</style>
