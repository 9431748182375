var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-list-table"
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.investments.investments,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": _vm.getColumnSortType,
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-per-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'name' ? _c('span', [_vm._v(" " + _vm._s(row.name) + " ")]) : _vm._e(), column.field === 'investor' ? _c('span', [row.investor ? [_vm._v(" " + _vm._s(row.investor.name) + " ")] : _vm._e()], 2) : _vm._e(), column.field === 'principal' ? _c('span', [row.principal ? [_vm._v(" " + _vm._s(row.principal.name) + " ")] : _vm._e()], 2) : _vm._e(), column.field === 'core_branch_id' ? _c('span', [_c('branch-label', {
          attrs: {
            "id": row.core_branch_id
          }
        })], 1) : _vm._e(), column.field === 'estimatedScheduler.start_date' ? _c('span', [row.estimated_scheduler ? [_vm._v(" " + _vm._s(_vm.formatDate.training(row.estimated_scheduler.start_date)) + " ")] : _vm._e()], 2) : _vm._e(), column.field === 'estimatedScheduler.end_date' ? _c('span', [row.estimated_scheduler ? [_vm._v(" " + _vm._s(_vm.formatDate.training(row.estimated_scheduler.end_date)) + " ")] : _vm._e()], 2) : _vm._e(), column.field === 'investment_status_id' ? _c('span', [_c('vs-status-badge-investment', {
          attrs: {
            "id": row.investment_status_id
          }
        })], 1) : _vm._e(), column.field === 'work_type' ? _c('span', _vm._l(row.work_types, function (type) {
          return _c('span', {
            key: type.id,
            staticClass: "work-type-item"
          }, [_vm._v(" " + _vm._s(type.name) + " ")]);
        }), 0) : _vm._e(), column.field === 'percentage' ? _c('span', [_vm._v(" " + _vm._s(row.completionPercentage.percentage || 0) + "% ")]) : _vm._e()];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }