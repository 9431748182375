<template>
  <div class="card shadow-none bg-transparent border-secondary">
    <div class="card-body">
      <h4 class="card-title">
        {{ $t('global.title.filtering') }}
      </h4>
      <b-row>

        <b-col cols="3">
          <b-form-group
            :label="$t('investments.global.field.status')"
          >
            <investments-status-picker
              multiple
              v-model="filters.status"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            :label="$t('investments.global.field.region')"
          >
            <core-branch-picker
              multiple
              v-model="filters.regions"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Technologia"
          >
            <base-technology-picker
              multiple
              v-model="filters.subject_technologies"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Typ robót"
            >
            <investments-work-type-picker
              multiple
              v-model="filters.work_types"
              :reduce="type => type.id"
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Data startu od"
            >
            <vs-datepicker
              placeholder="Wybierz datę początkową"
              v-model="dateFrom"
              reset-button
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Data startu do"
            >
            <vs-datepicker
              placeholder="Wybierz datę końcową"
              v-model="dateTo"
              reset-button
            />
          </b-form-group>
        </b-col>

        <b-col cols="3">
          <b-form-group
            label="Zleceniodawca"
            >
            <crm-client-picker
              multiple
              v-model="filters.principals"
              :reduce="client => client.id"
            />
          </b-form-group>
        </b-col>

      </b-row>
      <a href="#" @click="clearAllFilters">
        {{ $t('global.table.clearAllFilters') }}
      </a>
    </div>
  </div>
</template>

<script>
import {
  BCol, BRow, BFormGroup,
} from 'bootstrap-vue';
import BaseTechnologyPicker from '@/components/module/base/BaseTechnologyPicker.vue';
import CoreBranchPicker from '@/components/module/core/CoreBranchPicker.vue';
import CrmClientPicker from '@/components/module/crm/CrmClientPicker.vue';
import InvestmentsStatusPicker from '@/components/module/investments/InvestmentsStatusPicker.vue';
import InvestmentsWorkTypePicker from '@/components/module/investments/InvestmentsWorkTypePicker.vue';
import formatDate from '@/libs/date-formatter';
import investmentsTable from '@/hooks/tables/investments/investments';
import { computed } from 'vue';
import VsDatepicker from '@/components/ui/vs-datepicker/VsDatepicker.vue';

export default {
  name: 'InvestmentsListFiltration',
  setup() {
    const { table, filters, clearAllFilters } = investmentsTable.value;

    const dateFrom = computed({
      get() {
        return formatDate.standard(filters.estimated_start_date_from);
      },
      set(newDate) {
        filters.estimated_start_date_from = newDate;
      },
    });

    const dateTo = computed({
      get() {
        return formatDate.standard(filters.estimated_start_date_to);
      },
      set(newDate) {
        filters.estimated_start_date_to = newDate;
      },
    });

    return {
      filters,
      clearAllFilters,
      table,

      dateFrom,
      dateTo,
    };
  },
  components: {
    BCol,
    BRow,
    BFormGroup,

    BaseTechnologyPicker,
    InvestmentsWorkTypePicker,
    CrmClientPicker,
    InvestmentsStatusPicker,
    CoreBranchPicker,
    VsDatepicker,
  },
};
</script>
