<template>
  <vs-modal
    :modalInstance="modalEditFueling"
    @ok="updateFueling"
    @hide="resetFields"
  >
    <investments-fueling-form
      :value="fields"
      :feedback="feedback"
    />
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useInvestmentsModals from '@/hooks/investments/useInvestmentsModals';
import useValidatedForm from '@/hooks/useValidatedForm';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import {
  UpdateFuelingSuccess,
  UpdateFuelingError,
} from '@/libs/toasts/feedback/module/investments';
import showToast from '@/libs/toasts';
import { watch } from 'vue';
import investmentsFueling from '@/hooks/tables/investments/investmentsFueling';
import InvestmentsFuelingForm from '../../forms/fueling/InvestmentsFuelingForm.vue';

export default {
  name: 'InvestmentsCreateRefuelingModal',
  setup() {
    const { modalEditFueling } = useInvestmentsModals();

    const {
      fields,
      feedback,
      areAllFieldsValid,
      assignFields,
      resetFields,
    } = useValidatedForm({
      id: 'required',
      order_date: 'required',
      amount_fuel: 'required|integer|positive',
      crm_provider_id: 'required',
      reporting_id: 'required',
      investment_id: 'required',
      comment: '',
    });

    watch(() => modalEditFueling.value.visible, () => {
      resetFields();
      assignFields(modalEditFueling.value.data);
    });

    const updateFueling = async () => {
      const isValid = await areAllFieldsValid();
      if (!isValid) return;

      modalEditFueling.value.isLoading = true;

      useInvestmentsApi()
        .updateFueling(fields.value.id, fields.value)
        .then(() => {
          showToast(UpdateFuelingSuccess);
          modalEditFueling.value.close();
          investmentsFueling.value.fetchData();
        })
        .catch(() => {
          showToast(UpdateFuelingError);
        })
        .finally(() => {
          modalEditFueling.value.isLoading = false;
        });
    };

    return {
      modalEditFueling,
      updateFueling,
      fields,
      resetFields,
      feedback,
    };
  },
  components: { VsModal, InvestmentsFuelingForm },
};
</script>
