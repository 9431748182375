<template>
  <vs-modal
    :modalInstance="modalFuelingDetails"
  >
    <div class="investments-fueling-details-modal">

      <div class="controls">
        <widget-card-controls
          @edit="$emit('edit', fueling)"
          @delete="$emit('delete', fueling)"
        />
      </div>

      <div class="field">
        <div class="label">Data dostawy</div>
        <div class="value">
          {{ formatDate.investmentFuel(fueling.order_date) }}
        </div>
      </div>

      <div class="field">
        <div class="label">Inwestycja</div>
        <div class="value">
          <investments-investment-label
            :investment="fueling.investment"
          />
        </div>
      </div>

      <div class="field">
        <div class="label">Ilość paliwa</div>
        <div class="value">
          {{ fueling.amount_fuel }} l
        </div>
      </div>

      <div class="field">
        <div class="label">Dostawca</div>
        <div class="value">
          <crm-company-label
            v-if="fueling.provider"
            companyType="provider"
            :integrated="fueling.provider.company"
          />
        </div>
      </div>

      <div class="field">
        <div class="label">Raportujący</div>
        <div class="value">
          <user-label withAvatar :data="fueling.reporter" />
        </div>
      </div>

      <div class="field">
        <div class="label">Uwagi</div>
        <div class="value" v-html="fueling.comment"/>
      </div>

    </div>
  </vs-modal>
</template>

<script>
import VsModal from '@/components/ui/vs-modal/VsModal.vue';
import useInvestmentsModals from '@/hooks/investments/useInvestmentsModals';
import { ref, watch } from 'vue';
import CrmCompanyLabel from '@/components/module/crm/CrmCompanyLabel.vue';
import UserLabel from '@/components/ui/UserLabel.vue';
import WidgetCardControls from '@/components/ui/WidgetCardControls.vue';
import formatDate from '@/libs/date-formatter';
import InvestmentsInvestmentLabel from '../../InvestmentsInvestmentLabel.vue';

export default {
  name: 'InvestmentsFuelingDetailsModal',
  emits: ['delete', 'edit'],
  setup() {
    const { modalFuelingDetails } = useInvestmentsModals();
    const fueling = ref({});

    watch(() => modalFuelingDetails.value.visible, () => {
      fueling.value = modalFuelingDetails.value.data ?? {};
      modalFuelingDetails.value.data = {};
    });

    return {
      modalFuelingDetails,
      fueling,
      formatDate,
    };
  },
  components: {
    VsModal,
    InvestmentsInvestmentLabel,
    CrmCompanyLabel,
    UserLabel,
    WidgetCardControls,
  },
};
</script>

<style lang="sass" scoped>
.investments-fueling-details-modal
  position: relative

  .controls
    display: flex
    position: absolute
    z-index: 10
    width: 100%

    .widget-card-controls
      margin-left: auto

  .field
    margin: 10px 0

    .label
      margin-bottom: 3px
      opacity: 0.6

    .value
      font-size: 14pt
</style>
