var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-report-details-modal"
  }, [_c('vs-modal', {
    attrs: {
      "modalInstance": _vm.modalReportDetails
    },
    scopedSlots: _vm._u([{
      key: "default",
      fn: function (_ref) {
        var data = _ref.data;
        return [_c('form-steps-indicator', {
          attrs: {
            "current-step": _vm.step,
            "interactive": ""
          },
          on: {
            "stepChange": function (i) {
              return _vm.step = i;
            }
          }
        }), data && data.brigade && _vm.step === 0 ? _c('div', {
          staticClass: "details"
        }, [_c('div', {
          staticClass: "entry"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Data raportu")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(" " + _vm._s(_vm.formatDate.baseReport(data.daily_balance_date)) + " ")])]), _c('div', {
          staticClass: "entry"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Inwestycja")]), _c('div', {
          staticClass: "value"
        }, [_c('investments-investment-label', {
          attrs: {
            "investment": data.brigade.investment
          }
        })], 1)]), _c('div', {
          staticClass: "entry"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Brygada zarządzająca")]), _vm.managementBrigadeVehicles.length ? _c('div', {
          staticClass: "vehicles"
        }, [_c('div', {
          staticClass: "vehicle main"
        }, [_vm._v(" Maszyna główna: "), _c('base-resource-label', {
          attrs: {
            "resource": _vm.getMainVehicleFromArray(_vm.managementBrigadeVehicles)
          }
        })], 1), _vm.getSecondaryVehiclesFromArray(_vm.managementBrigadeVehicles) ? _c('div', {
          staticClass: "vehicle"
        }, [_vm._v(" Zasoby dodatkowe: "), _vm._l(_vm.getSecondaryVehiclesFromArray(_vm.managementBrigadeVehicles), function (vehicle) {
          return _c('div', {
            key: vehicle.id,
            staticClass: "secondary-vehicle"
          }, [_c('base-resource-label', {
            attrs: {
              "resource": vehicle
            }
          })], 1);
        })], 2) : _vm._e()]) : _vm._e(), _c('div', {
          staticClass: "value-employees"
        }, [_vm.isLoading ? _c('vs-loader', {
          attrs: {
            "inline": "",
            "text": "Wczytywanie zawartości"
          }
        }) : _vm.managementEmployees && _vm.managementEmployees.length ? _vm._l(_vm.managementEmployees, function (employee) {
          return _c('user-label', {
            key: employee.id,
            attrs: {
              "isEmployee": "",
              "data": employee.cadre_employee,
              "showLink": ""
            },
            scopedSlots: _vm._u([{
              key: "extra-info",
              fn: function () {
                return [_vm._v("(" + _vm._s(employee.cadre_position.name) + ")")];
              },
              proxy: true
            }], null, true)
          });
        }) : _vm._e()], 2)]), _c('div', {
          staticClass: "entry"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Raportujący")]), _c('div', {
          staticClass: "value value-user"
        }, [_c('user-label', {
          attrs: {
            "withAvatar": "",
            "id": data.reporter_id
          }
        })], 1)]), _c('div', {
          staticClass: "entry"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Praca (od - do)")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(" " + _vm._s(_vm.formatDate.getTime(data.work_scheduler.start_date)) + " - " + _vm._s(_vm.formatDate.getTime(data.work_scheduler.end_date)) + " ")])]), _c('div', {
          staticClass: "entry"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Produkcja (od - do)")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(" " + _vm._s(_vm.formatDate.getTime(data.production_scheduler.start_date)) + " - " + _vm._s(_vm.formatDate.getTime(data.production_scheduler.end_date)) + " ")])]), _c('div', {
          staticClass: "entry"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Brygada")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(" " + _vm._s(data.brigade.name) + " ")]), _vm.assignedBrigadeVehicles.length ? _c('div', {
          staticClass: "vehicles"
        }, [_c('div', {
          staticClass: "vehicle main"
        }, [_vm._v(" Maszyna główna: "), _c('base-resource-label', {
          attrs: {
            "resource": _vm.getMainVehicleFromArray(_vm.assignedBrigadeVehicles)
          }
        })], 1), _vm.getSecondaryVehiclesFromArray(_vm.assignedBrigadeVehicles) ? _c('div', {
          staticClass: "vehicle"
        }, [_vm._v(" Zasoby dodatkowe: "), _vm._l(_vm.getSecondaryVehiclesFromArray(_vm.assignedBrigadeVehicles), function (vehicle) {
          return _c('div', {
            key: vehicle.id,
            staticClass: "secondary-vehicle"
          }, [_c('base-resource-label', {
            attrs: {
              "resource": vehicle
            }
          })], 1);
        })], 2) : _vm._e()]) : _vm._e(), _c('div', {
          staticClass: "value-employees"
        }, [_vm.isLoading ? _c('vs-loader', {
          attrs: {
            "inline": "",
            "text": "Wczytywanie zawartości"
          }
        }) : _vm.assignedEmployees && _vm.assignedEmployees.length ? _vm._l(_vm.assignedEmployees, function (employee) {
          return _c('user-label', {
            key: employee.id,
            attrs: {
              "isEmployee": "",
              "data": employee.cadre_employee,
              "showLink": ""
            },
            scopedSlots: _vm._u([{
              key: "extra-info",
              fn: function () {
                return [_c('position-label', {
                  attrs: {
                    "id": employee.cadre_position_id
                  },
                  scopedSlots: _vm._u([{
                    key: "default",
                    fn: function (_ref2) {
                      var label = _ref2.label;
                      return [_vm._v(" (" + _vm._s(label) + ") ")];
                    }
                  }], null, true)
                })];
              },
              proxy: true
            }], null, true)
          });
        }) : _vm._e()], 2)]), _c('div', {
          staticClass: "entry"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Przerób dzienny")]), _c('div', {
          staticClass: "value-table"
        }, [_vm.isLoading ? _c('vs-loader', {
          attrs: {
            "inline": "",
            "text": "Wczytywanie zawartości"
          }
        }) : _vm.reportProgress.length ? _c('investments-report-progress-table', {
          attrs: {
            "progress": _vm.reportProgress
          }
        }) : _c('div', {
          staticClass: "info"
        }, [_vm._v(" Nie znaleziono zawartości dla wybranego raportu ")])], 1)]), _c('div', {
          staticClass: "entry"
        }, [_c('div', {
          staticClass: "label"
        }, [_vm._v("Uwagi")]), _c('div', {
          staticClass: "value"
        }, [_vm._v(" " + _vm._s(data.description || '-') + " ")])])]) : data && data.brigade && (_vm.step === 1 || _vm.step === 2) ? _c('investments-report-form-fuel', {
          attrs: {
            "disableAdd": "",
            "disableEdit": "",
            "reportType": _vm.step === 1 ? 'work' : 'fuel',
            "investment": _vm.selectedInvestment,
            "reportDate": data.daily_balance_date
          },
          on: {
            "update:investment": _vm.updateSelectedInvestment
          }
        }) : _c('div', {
          staticClass: "list"
        }, [_c('investments-report-form-details', {
          attrs: {
            "investment": _vm.selectedInvestment,
            "reportDate": data.daily_balance_date
          }
        }), _c('investments-report-form-list', {
          attrs: {
            "disableEdit": "",
            "value": _vm.additionalCosts,
            "noteValue": _vm.additionalCostsNote
          }
        })], 1)];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }