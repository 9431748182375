var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-report-progress-table"
  }, [_c('vs-table', {
    attrs: {
      "columns": _vm.columns,
      "rows": _vm.rows,
      "show-search": false,
      "show-pagination": false
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var row = _ref.row,
          column = _ref.column,
          index = _ref.index;
        return [column.isEditable && _vm.editable ? _c('span', [_c('b-form-input', {
          attrs: {
            "state": _vm.isEditableValid(row[column.field]),
            "placeholder": "0"
          },
          on: {
            "input": function () {
              return _vm.updateProgressRow(index, column.field, row[column.field]);
            }
          },
          model: {
            value: row[column.field],
            callback: function ($$v) {
              _vm.$set(row, column.field, $$v);
            },
            expression: "row[column.field]"
          }
        })], 1) : column.materialId && _vm.editable ? _c('span', [_c('b-form-input', {
          attrs: {
            "state": _vm.isEditableValid(_vm.getMaterialValue(row, column).quantity),
            "placeholder": "0"
          },
          on: {
            "input": function () {
              return _vm.updateProgressMaterial(index, row, column);
            }
          },
          model: {
            value: _vm.getMaterialValue(row, column).quantity,
            callback: function ($$v) {
              _vm.$set(_vm.getMaterialValue(row, column), "quantity", $$v);
            },
            expression: "getMaterialValue(row, column).quantity"
          }
        })], 1) : column.field === 'material' ? _c('span', [_vm._v(" " + _vm._s(row.material.find(function (m) {
          return m.materialId === column.materialId;
        }).quantity) + " " + _vm._s(row.material.find(function (m) {
          return m.materialId === column.materialId;
        }).unit) + " ")]) : column.unit ? _c('span', [_vm._v(" " + _vm._s(row[column.field]) + " " + _vm._s(column.unit) + " ")]) : _vm._e()];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }