<template>
  <div class="investments-list-table">
    <vs-table showActionButton
      mode="remote"
      :columns="columns"
      :rows="$store.state.investments.investments"
      :totalRecords="table.totalRecords"
      :current-page="table.page"
      :checkSort="getColumnSortType"
      :perPage="table.perPage"
      :searchTerm="searchTerm"
      :actionItems="actionItems"
      :isLoading="table.isLoading"
      @on-row-click="openDetails"
      @searchTermChange="(term) => updateSearchTerm(term)"
      @on-page-change="onPageChange"
      @on-per-page-change="onPageChange"
      @on-sort-change="onSortChange"
    >
      <template v-slot:row="{ column, row }" >
        <span v-if="column.field === 'name'">
          {{ row.name }}
        </span>
        <span v-if="column.field === 'investor'">
          <template v-if="row.investor">
            {{ row.investor.name }}
          </template>
        </span>
        <span v-if="column.field === 'principal'">
          <template v-if="row.principal">
            {{ row.principal.name }}
          </template>
        </span>
        <span v-if="column.field === 'core_branch_id'">
          <branch-label :id="row.core_branch_id" />
        </span>
        <span v-if="column.field === 'estimatedScheduler.start_date'">
          <template v-if="row.estimated_scheduler">
            {{ formatDate.training(row.estimated_scheduler.start_date) }}
          </template>
        </span>
        <span v-if="column.field === 'estimatedScheduler.end_date'">
          <template v-if="row.estimated_scheduler">
            {{ formatDate.training(row.estimated_scheduler.end_date) }}
          </template>
        </span>
        <span v-if="column.field === 'investment_status_id'">
          <vs-status-badge-investment :id="row.investment_status_id" />
        </span>
        <span v-if="column.field === 'work_type'">
          <span
            v-for="type in row.work_types"
            :key="type.id"
            class="work-type-item"
          >
            {{ type.name }}
          </span>
        </span>
        <span v-if="column.field === 'percentage'">
          {{ row.completionPercentage.percentage || 0 }}%
        </span>
      </template>
    </vs-table>
  </div>
</template>

<script>
import router from '@/router';

import BranchLabel from '@/components/ui/BranchLabel.vue';
import VsTable from '@/components/ui/vs-table/Table.vue';
import InvestmentsTable from '@/hooks/tables/investments/investments';
import VsStatusBadgeInvestment from '@/components/ui/vs-status-badge/investment/VsStatusBadgeInvestment.vue';
import i18n from '@/libs/i18n';
import showToast from '@/libs/toasts';
import { DeleteInvestmentError, DeleteInvestmentSuccess } from '@/libs/toasts/feedback/module/investments';
import useInvestmentsApi from '@/hooks/investments/useInvestmentsApi';
import { deleteModal } from '@/libs/modals';
import formatDate from '@/libs/date-formatter';
import RemoteResourceManager from '@/libs/RemoteResourceManager';

export default {
  name: 'InvestmentsListTable',
  setup() {
    const {
      table, updateSearchTerm, searchTerm, columns, fetchData,
      onPageChange, rows, changeSorting, getColumnSorting,
    } = InvestmentsTable.value;

    fetchData();

    const actionItems = [
      {
        title: 'Edytuj',
        icon: 'Edit2',
        callback: (obj) => {
          router.push({ name: 'investments.details.settings', params: { id: obj.id } });
        },
      },
      {
        title: 'Usuń',
        icon: 'Trash',
        callback: async (obj) => {
          const confirmation = await deleteModal({
            title: i18n.t('investments.modal.investment.delete.title'),
            text: i18n.t('investments.modal.investment.delete.text', {
              name: obj.name,
            }),
          });

          if (!confirmation) return false;

          const request = useInvestmentsApi()
            .deleteInvestment(obj.id)
            .then(() => {
              showToast(DeleteInvestmentSuccess, { name: obj.name });
              RemoteResourceManager.getInstance().notify('investments.investment');
              fetchData();
            })
            .catch(() => {
              showToast(DeleteInvestmentError);
            });

          return request;
        },
      },
    ];

    const onSortChange = (data) => {
      const { field, type } = data[0];
      changeSorting({ field, type });
    };

    const getColumnSortType = (field) => getColumnSorting(field)?.type;

    return {
      table,
      rows,
      updateSearchTerm,
      searchTerm,
      columns,
      onPageChange,
      actionItems,
      onSortChange,
      formatDate,
      getColumnSortType,
    };
  },
  methods: {
    openDetails(data) {
      this.$router.push({ name: 'investments.details', params: { id: data.row.id } });
    },
  },
  components: {
    VsTable,
    BranchLabel,
    VsStatusBadgeInvestment,
  },
};
</script>

<style lang="sass" scoped>
.work-type-item
  &:not(:last-child):after
    margin-left: -3px
    content: ', '
</style>
