var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-reports"
  }, [_c('b-card', [_c('authorization-guard', {
    attrs: {
      "permission": "investment.subject_balance.index",
      "silent": ""
    }
  }, [_c('investments-reports-filtration')], 1), _c('div', {
    staticClass: "buttons"
  }, [_c('vs-button', {
    directives: [{
      name: "can",
      rawName: "v-can:store",
      value: 'investment.subject_balance',
      expression: "'investment.subject_balance'",
      arg: "store"
    }],
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.investmentAddReportModal.visible = true;
      }
    }
  }, [_vm._v(" + Raport dzienny ")])], 1), _c('authorization-guard', {
    attrs: {
      "permission": "investment.subject_balance.index"
    }
  }, [_c('investments-reports-table')], 1), _c('investments-report-details-modal')], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }