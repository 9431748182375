var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-report-edit-form"
  }, [_vm.isLoading ? _c('vs-loader', {
    attrs: {
      "full": "",
      "text": "Wczytywanie zawartości"
    }
  }) : _vm._e(), _c('vs-form', {
    attrs: {
      "i18nNamespace": "investments.form.report.field",
      "errors": _vm.errors
    }
  }, [_c('vs-form-group', {
    attrs: {
      "field": "investment_id"
    }
  }, [_c('investments-investment-picker', {
    attrs: {
      "reduce": _vm.investmentReducer,
      "disabled": ""
    },
    on: {
      "ready": function ($event) {
        _vm.isLoading = false;
      }
    },
    model: {
      value: _vm.report.investment_id,
      callback: function ($$v) {
        _vm.$set(_vm.report, "investment_id", $$v);
      },
      expression: "report.investment_id"
    }
  })], 1), _c('vs-form-group', {
    staticClass: "management-list",
    attrs: {
      "field": "management"
    }
  }, [_vm.selectedInvestmentManagement && _vm.selectedInvestmentManagement.length ? _vm._l(_vm.selectedInvestmentManagement, function (employee) {
    return _c('user-label', {
      key: employee.id,
      attrs: {
        "isEmployee": "",
        "data": employee.cadre_employee,
        "showLink": "",
        "linkIconVisible": ""
      },
      scopedSlots: _vm._u([{
        key: "extra-info",
        fn: function () {
          return [_vm._v("(" + _vm._s(employee.cadre_position.name) + ")")];
        },
        proxy: true
      }], null, true)
    });
  }) : _vm.selectedInvestmentManagement && !_vm.selectedInvestmentManagement.length ? _c('div', {
    staticClass: "no-management-info"
  }, [_vm._v(" Brygada kierownicza wybranej inwestycji jest pusta ")]) : _vm.selectedInvestment.id && !_vm.selectedInvestmentManagement ? _c('div', {
    staticClass: "no-management-info"
  }, [_vm._v(" Wybrana inwestycja nie posiada brygady kierowniczej ")]) : _c('div', {
    staticClass: "no-management-info"
  }, [_vm._v(" Wybierz inwestycję, aby zobaczyć jej brygadę kierowniczą ")])], 2), _c('vs-form-group', {
    attrs: {
      "field": "daily_balance_date",
      "cols": "9",
      "labelCols": "4"
    }
  }, [_c('vs-datepicker', {
    attrs: {
      "placeholder": "Wybierz datę raportu"
    },
    model: {
      value: _vm.report.daily_balance_date,
      callback: function ($$v) {
        _vm.$set(_vm.report, "daily_balance_date", $$v);
      },
      expression: "report.daily_balance_date"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "report_time",
      "no-label": "",
      "cols": "3"
    }
  }, [_c('b-form-timepicker', {
    attrs: {
      "hour12": false,
      "placeholder": ""
    },
    model: {
      value: _vm.report.report_time,
      callback: function ($$v) {
        _vm.$set(_vm.report, "report_time", $$v);
      },
      expression: "report.report_time"
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "reporter_id"
    }
  }, [_c('user-label', {
    attrs: {
      "withAvatar": "",
      "showLink": ""
    }
  })], 1), _c('vs-form-group', {
    attrs: {
      "field": "work_time",
      "catches": ['work_time_from', 'work_time_to']
    }
  }, [_c('div', {
    staticClass: "time-range"
  }, [_c('b-form-timepicker', {
    staticClass: "time-range-picker",
    attrs: {
      "hour12": false,
      "placeholder": ""
    },
    model: {
      value: _vm.report.work_time_from,
      callback: function ($$v) {
        _vm.$set(_vm.report, "work_time_from", $$v);
      },
      expression: "report.work_time_from"
    }
  }), _c('div', {
    staticClass: "time-range-divider"
  }), _c('b-form-timepicker', {
    staticClass: "time-range-picker",
    attrs: {
      "hour12": false,
      "placeholder": ""
    },
    model: {
      value: _vm.report.work_time_to,
      callback: function ($$v) {
        _vm.$set(_vm.report, "work_time_to", $$v);
      },
      expression: "report.work_time_to"
    }
  })], 1)]), _c('vs-form-group', {
    attrs: {
      "field": "production_time",
      "catches": ['production_time_from', 'production_time_to']
    }
  }, [_c('div', {
    staticClass: "time-range"
  }, [_c('b-form-timepicker', {
    staticClass: "time-range-picker",
    attrs: {
      "hour12": false,
      "placeholder": ""
    },
    model: {
      value: _vm.report.production_time_from,
      callback: function ($$v) {
        _vm.$set(_vm.report, "production_time_from", $$v);
      },
      expression: "report.production_time_from"
    }
  }), _c('div', {
    staticClass: "time-range-divider"
  }), _c('b-form-timepicker', {
    staticClass: "time-range-picker",
    attrs: {
      "hour12": false,
      "placeholder": ""
    },
    model: {
      value: _vm.report.production_time_to,
      callback: function ($$v) {
        _vm.$set(_vm.report, "production_time_to", $$v);
      },
      expression: "report.production_time_to"
    }
  })], 1)]), _c('vs-form-group', {
    attrs: {
      "field": "investment_brigade_id",
      "info": "Wybór spośród brygad wybranej inwestycji, posiadających przypisanie do maszyny"
    }
  }, [_vm.selectedInvestment && _vm.selectedInvestment.id ? _c('investments-brigade-picker', {
    attrs: {
      "withMainVehicleOnly": "",
      "autoselect": "",
      "reduce": _vm.brigadeReducer,
      "investment-id": _vm.report.investment_id
    },
    model: {
      value: _vm.report.investment_brigade_id,
      callback: function ($$v) {
        _vm.$set(_vm.report, "investment_brigade_id", $$v);
      },
      expression: "report.investment_brigade_id"
    }
  }) : _c('div', {
    staticClass: "no-management-info"
  }, [_vm._v(" Przed wybraniem brygady musisz wybrać inwestycję ")]), _vm.selectedBrigade && _vm.selectedBrigade.employees ? [_c('vs-employee-multiselect', {
    attrs: {
      "employees": _vm.sortedEmployees
    },
    on: {
      "add:employee": _vm.addReportedEmployee,
      "delete:employee": _vm.deleteReportedEmployee,
      "update:position": _vm.updateReportedEmployeePosition
    },
    scopedSlots: _vm._u([{
      key: "employee-subtitle",
      fn: function (_ref) {
        var employee = _ref.employee;
        return [_vm.employeeInSelectedBrigade(employee.cadre_employee_id) ? _c('div', {
          staticClass: "brigade-employee-info"
        }, [_vm._v(" Z wybranej brygady ")]) : _vm._e(), employee.brigadeName ? _c('div', {
          staticClass: "brigade-employee-info"
        }, [_vm._v(" " + _vm._s(employee.brigadeName) + " ")]) : _vm._e()];
      }
    }], null, false, 2749457344)
  })] : _vm._e()], 2), _c('h4', [_vm._v("Przerób dzienny")]), _vm.rows.length ? _c('div', {
    staticClass: "vs-table-wrapper"
  }, [_vm.reportProgress.length ? _c('investments-report-progress-table', {
    attrs: {
      "editable": "",
      "progress": _vm.reportProgress
    },
    on: {
      "updateRow": _vm.updateProgressRow,
      "updateMaterial": _vm.updateProgressMaterial
    }
  }) : _c('div', {
    staticClass: "info"
  }, [_vm._v(" Nie znaleziono zawartości dla wybranego raportu ")])], 1) : _c('div', {
    staticClass: "no-requirements-info"
  }, [_vm._v(" Wybrana inwestycja nie zawiera żadnych przedmiotów zamówienia ")]), _c('h4', [_vm._v("Uwagi")]), _c('b-form-textarea', {
    model: {
      value: _vm.report.description,
      callback: function ($$v) {
        _vm.$set(_vm.report, "description", $$v);
      },
      expression: "report.description"
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }