<template>
  <div class="investments-reports">
    <b-card>
      <authorization-guard
        permission="investment.subject_balance.index"
        silent
      >
        <investments-reports-filtration />
      </authorization-guard>

      <div class="buttons">
        <vs-button
          vs-variant="primary"
          @click="investmentAddReportModal.visible = true"
          v-can:store="'investment.subject_balance'"
        >
          + Raport dzienny
        </vs-button>
      </div>

      <authorization-guard
        permission="investment.subject_balance.index"
      >
        <investments-reports-table />
      </authorization-guard>

      <investments-report-details-modal />
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
} from 'bootstrap-vue';
import InvestmentsReportsFiltration from '@/components/views/investments/InvestmentsReportsFiltration.vue';
import VsButton from '@/components/ui/vs-button/VsButton.vue';
import InvestmentsReportsTable from '@/components/views/investments/InvestmentsReportsTable.vue';
import useInvestmentsStore from '@/hooks/investments/useInvestmentsStore';
import InvestmentsReportDetailsModal from '@/components/views/investments/modals/InvestmentsReportDetailsModal.vue';
import AuthorizationGuard from '@/components/ui/AuthorizationGuard.vue';

export default {
  name: 'InvestmentsReports',
  setup() {
    const { investmentAddReportModal } = useInvestmentsStore();

    return { investmentAddReportModal };
  },
  components: {
    BCard,
    InvestmentsReportsFiltration,
    VsButton,
    InvestmentsReportsTable,
    InvestmentsReportDetailsModal,
    AuthorizationGuard,
  },
};
</script>

<style lang="sass" scoped>
.buttons
  margin-bottom: 20px
</style>
