var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-reports-table"
  }, [_c('vs-table', {
    attrs: {
      "showActionButton": "",
      "mode": "remote",
      "columns": _vm.columns,
      "rows": _vm.$store.state.investments.reports.reports,
      "totalRecords": _vm.table.totalRecords,
      "current-page": _vm.table.page,
      "checkSort": _vm.getColumnSortType,
      "perPage": _vm.table.perPage,
      "searchTerm": _vm.searchTerm,
      "actionItems": _vm.actionItems,
      "isLoading": _vm.table.isLoading
    },
    on: {
      "on-row-click": _vm.openDetails,
      "searchTermChange": function (term) {
        return _vm.updateSearchTerm(term);
      },
      "on-page-change": _vm.onPageChange,
      "on-sort-change": _vm.onSortChange,
      "on-per-page-change": _vm.onPageChange
    },
    scopedSlots: _vm._u([{
      key: "row",
      fn: function (_ref) {
        var column = _ref.column,
          row = _ref.row;
        return [column.field === 'work_hours' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.getTime(row.work_scheduler.start_date)) + " - " + _vm._s(_vm.formatDate.getTime(row.work_scheduler.end_date)) + " ")]) : column.field === 'brigade' ? _c('span', [_vm._v(" " + _vm._s(row.brigade.name) + " ")]) : column.field === 'investment' ? _c('span', {
          on: {
            "click": function ($event) {
              $event.stopPropagation();
            }
          }
        }, [_c('investments-investment-label', {
          attrs: {
            "investment": row.brigade.investment
          }
        })], 1) : column.field === 'reporter' ? _c('span', [_c('user-label', {
          attrs: {
            "withAvatar": "",
            "id": row.reporter_id
          }
        })], 1) : column.field === 'daily_balance_date' ? _c('span', [_vm._v(" " + _vm._s(_vm.formatDate.baseReport(row.daily_balance_date)) + " ")]) : _vm._e()];
      }
    }])
  }), _c('investments-report-edit-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }