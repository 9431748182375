var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "investments-list"
  }, [_c('b-tabs', {
    on: {
      "activate-tab": _vm.changeTab
    }
  }, [_c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('investments')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "CompassIcon"
          }
        }), _c('span', [_vm._v("Inwestycje")])];
      },
      proxy: true
    }])
  }, [_c('b-card', [_c('authorization-guard', {
    attrs: {
      "permission": "investment.investment.index",
      "silent": ""
    }
  }, [_c('investments-list-filtration')], 1), _c('div', {
    staticClass: "buttons"
  }, [_c('authorization-guard', {
    attrs: {
      "permission": "investment.investment.store",
      "silent": ""
    }
  }, [_c('vs-button', {
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.investmentCreateModal = true;
      }
    }
  }, [_vm._v(" + " + _vm._s(_vm.$t('investments.button.addInvestment')) + " ")])], 1)], 1), _c('br'), _c('authorization-guard', {
    attrs: {
      "permission": "investment.investment.index"
    }
  }, [_c('investments-list-table')], 1)], 1)], 1), _c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('reports')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "ActivityIcon"
          }
        }), _c('span', [_vm._v("Raporty")])];
      },
      proxy: true
    }])
  }, [_c('investments-reports')], 1), _c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('processing')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "BarChart2Icon"
          }
        }), _c('span', [_vm._v("Przeroby")])];
      },
      proxy: true
    }])
  }, [_c('investments-processing')], 1), _c('b-tab', {
    attrs: {
      "lazy": "",
      "active": _vm.isTabActive('fueling')
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('feather-icon', {
          attrs: {
            "icon": "DropletIcon"
          }
        }), _c('span', [_vm._v("Paliwo")])];
      },
      proxy: true
    }])
  }, [_c('b-card', [_c('authorization-guard', {
    attrs: {
      "permission": "investment.fuel.index",
      "silent": ""
    }
  }, [_c('investments-fueling-filtration')], 1), _c('authorization-guard', {
    attrs: {
      "permission": "investment.fuel.store",
      "silent": ""
    }
  }, [_c('vs-button', {
    staticClass: "mb-2",
    attrs: {
      "vs-variant": "primary"
    },
    on: {
      "click": _vm.openFuelingModal
    }
  }, [_vm._v(" + Dodaj dostawę ")])], 1), _c('authorization-guard', {
    attrs: {
      "permission": "investment.fuel.index"
    }
  }, [_c('investments-fueling-table')], 1), _c('investments-create-fueling-modal', {
    on: {
      "success": _vm.fetchData
    }
  })], 1)], 1)], 1), _c('investments-create-modal'), _c('investments-report-modal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }